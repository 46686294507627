import $ from "jquery";

const LanguageModal = () => {
  const click = (e) => {
    let lang = $(e.target).attr('data-lang') || 'ko';
    localStorage && localStorage.setItem('lang', lang);
    let urlLang =  window.location.pathname.replace(/^.{3}/, `/${lang}`);
    urlLang === '/en/press.html' ? 
      window.location.href = "/en/index.html" 
      :  window.location.href = urlLang
  };
  const goJapan = () => {
    window.location.href = 'https://quadminers.co.jp';
  }
  return (
    <div className="language-modal">
      <h4>Choose your Language</h4>
      <ul>
        <li onClick={click} data-lang="en">English</li>
        <li onClick={click} data-lang="ko">한국어</li>
        <li onClick={goJapan}>日本語</li>
      </ul>
    </div>
  )
}

export default LanguageModal;