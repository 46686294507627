import Wrapper from 'common/Wrapper';
import 'styles/CloudBlackbox.scss';
import { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDatabase, faSearch, faAnalytics, faFileChartLine,
  faSackDollar, faTachometerAlt, faExclamationTriangle
} from "@fortawesome/pro-light-svg-icons";
import $ from "jquery";
import {
  Packet, Detection, ThreatResponse, Analysis, Ai, Contactus
} from "en/NetworkBlackbox";

const CloudBlackbox = () => {
  return (
    <Wrapper active="CloudBlackbox" lang="en">
      <Intro />
      <Slogan />
      <Chain />
      <Structure />
      <Collect />
      <Accounting />
      <Packet />
      <Detection />
      <ThreatResponse />
      <Analysis />
      <Instance />
      <Ai />
      <Contactus />
    </Wrapper>
  )
};

const Intro = () => {
  const [isMount, setIsMount] = useState(false);
  useEffect(()=>{
    setIsMount(true)

    let c = document.getElementById('canvas').getContext('2d')
    let canvas = c.canvas
    let vertices = []

    // Effect Properties
    let vertexCount = 7000;
    let vertexSize = 1;
    let oceanWidth = 204;
    let oceanHeight = -80;
    let gridSize = 32;
    let waveSize = 16;
    let perspective = 100;

    // Common variables
    let depth = (vertexCount / oceanWidth * gridSize)
    let frame = 0;
    let { sin, cos, PI } = Math
    // let { sin, cos, tan, PI } = Math


    let loop = () => {
      let rad = sin(frame / 100) * PI / 20
      let rad2 = sin(frame / 50) * PI / 10
      frame++;

      c.fillStyle = `hsla(0, 100%, 0%, 0.1)`;
      c.fillRect(0, 0, canvas.width, canvas.height)
      c.save();
      c.translate(canvas.width / 2, canvas.height / 2);

      c.beginPath()
      vertices.forEach((vertex, i) => {
        // let ni = i + oceanWidth;
        let x = vertex[0] - frame % (gridSize * 2);
        let z = vertex[2] - frame * 2 % gridSize + (i % 2 === 0 ? gridSize / 2 : 0)
        let wave = (cos(frame / 45 + x / 50) - sin(frame / 20 + z / 50) + sin(frame / 30 + z*x / 10000));
        let y = vertex[1] + wave * waveSize;
        let a = Math.max(0, 1 - (Math.sqrt(x ** 2 + z ** 2)) / depth);
        let tx, ty, tz;
        y -= oceanHeight;
        tx = x; ty = y; tz = z;

        // Rotation Y
        tx = x * cos(rad) + z * sin(rad);
        tz = -x * sin(rad) + z * cos(rad);
        x = tx; y = ty; z = tz;

        // Rotation Z
        tx = x * cos(rad) - y * sin(rad);
        ty = x * sin(rad) + y * cos(rad);
        x = tx; y = ty; z = tz;

        // Rotation X
        ty = y * cos(rad2) - z * sin(rad2);
        tz = y * sin(rad2) + z * cos(rad2);
        x = tx; y = ty; z = tz;

        x /= z / perspective;
        y /= z / perspective;

        if (a < 0.01) return;
        if (z < 0) return;

        c.globalAlpha = a
        c.fillStyle = `hsl(${200 + wave * 20}deg, 100%, 50%)`
        c.fillRect(
          x - a * vertexSize / 2,
          y - a * vertexSize / 2,
          a * vertexSize,
          a * vertexSize
        );
        c.globalAlpha = 1;
      })
      c.restore();
      isMount && requestAnimationFrame(loop);
    };

    for (let i = 0; i < vertexCount; i++) {
      let x = i % oceanWidth
      let y = 0
      let z = i / oceanWidth >> 0
      let offset = oceanWidth / 2
      vertices.push([(-offset + x) * gridSize, y * gridSize, z * gridSize])
    }
    loop();

    return () => {
      setIsMount(false)
    };
  },[isMount]);

  return (
    <section className="cb-intro">
      <div className="background">
        <canvas id="canvas" />
      </div>
      <div className="container">
        <h2>
          <img src="/images/common/cb_center_white.png"
            alt="Cloud Blackbox Logo" />
          <br/>
          Network Traffic Analysis for Cloud
        </h2>
      </div>
    </section>
  )
}

const Slogan = () => {
  return (
    <section className="cb-slogan">
    <div className="container">
      <h2>
      Most advanced physical-virtualized network security solution in the market
      </h2>
    </div>
  </section>
  );
}

const Chain = () => {
  const containerRef = useRef();

  useEffect(()=>{
    $(()=>{
      // 가장 높이가 높은 DIV를 찾자
      let maxHeight = 0;
      $(containerRef.current).find('li>div').each(function(){
        let h = $(this).height();
        maxHeight = (maxHeight < h) ? h: maxHeight;
      });

      // 높이를 다 똑같게 만들자
      $(containerRef.current).find('li>div').each(function(){
        $(this).css('min-height', maxHeight);
      });
    });
  });
  return (
    <section className="cb-chain">
      <div className="container" ref={containerRef}>
        <ul>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon icon={faDatabase}
                  style={{color:'#adb5bd'}} /> Collect
              </h4>
              Including North-South/East-West traffic, Network Blackbox collects network traffic of both private and public cloud.
            </div>
          </li>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon icon={faSearch}
                  style={{color:'#adb5bd'}} /> Detect
              </h4>
              Detect advanced threats and network behavior anomaly in real time with the latest threat detection rules.
            </div>
          </li>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon icon={faAnalytics}
                  style={{color:'#adb5bd'}} /> Analysis
              </h4>
              Via highly refined DB, Network Blackbox users are able to analyze cyber threats and even see the actual packets.
            </div>
          </li>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon icon={faFileChartLine}
                  style={{color:'#adb5bd'}} />  Response
              </h4>
              Once an event is detected, it informs users of immediate countermeasure options via variety of paths.
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
}

const Structure = () => {
  return (
    <section className="cb-structure">
      <div className="container">
        <h2>
        Responding to ever-changing cloud environments
        </h2>
        <ul>
          <li>
            <div>
              <img src="/images/cloud_blackbox/aws.jpg" alt="aws" />
              <br/>
              Public cloud environment
            </div>
          </li>
          <li>
            <div>
              <img src="/images/cloud_blackbox/k8s_k8s.jpg" alt="k8s" />
              <br/>
              Private cloud environment
            </div>
          </li>
          <li>
            <div>
              <img src="/images/cloud_blackbox/all.jpg" alt="all" />
              <br/>
              Hybrid cloud environment
            </div>
          </li>
        </ul>
        <p>
          ※&nbsp;
          The current public cloud version is only supported on AWS as there will be support for Microsoft Azure and Google Cloud Platform.
        </p>
      </div>
    </section>
  );
}

const Collect = () => {
  return (
    <section className="cb-collect">
      <div className="container">
        <h2>
        Collection of network traffic in all directions
        </h2>
        <ul>
          <li>
            <div>
              <h4>North-South</h4>
            </div>
          </li>
          <li>
            <div>
              <h4>East-West</h4>
            </div>
          </li>
          <li>
            <div>
              <h4>Instance Level</h4>
            </div>
          </li>
        </ul>
        <p>
        Via uniquely constructed network interfaces, users can collect complete traffic, not only in the physical intervals but also in virtualized segments. Additionally, it collects traffic including communication between instances.
        </p>
      </div>
    </section>
  )
}

const Accounting = () => {
  return (
    <section className="cb-accounting">
      <div className="container">
        <h2>
          <FontAwesomeIcon
            icon={faSackDollar}
            style={{color:'#37b24d'}} /> Price customization options that optimizes the price
        </h2>
        <ul>
          <li>
          Reduce resources by selecting detection levels of system configuration.
          </li>
          <li>
          Users are able to operate at a reasonable price by selectively storing packets depending on user’s different environments and scenarios.
          </li>
        </ul>
      </div>
    </section>
  )
}

const Instance = () => {
  return (
    <section className="cb-instance">
      <div className="container">
        <h2>
        Visibility of instance and service
        </h2>
        <p>
        Users can analyze in an integrated on-premise and cloud environment, and are able to analyze in-depth network traffic of internal instance, service, and virtual machines.
        </p>
        <ul>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon
                  icon={faTachometerAlt} /> Performance Test
              </h4>
              Calculation of traffic level and its speed between the services
            </div>
          </li>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon
                  icon={faAnalytics} /> Instance behavior analysis
              </h4>
              Generate various actions based on timeline
            </div>
          </li>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon
                  icon={faExclamationTriangle} /> Warning System
              </h4>
              Notify detected events through various paths
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default CloudBlackbox;