const CLevelList = [
  {
    src:'ven.jpg', name:"Ven",
    ko:'최고경영자<br/>&nbsp;',
    en:'CEO',
    enDesc: 'Chief Executive Officer',
  },
  {
    src:'dennis.png', name:"Dennis",
    ko:'최고개발책임자<br/>&nbsp;',
    en:'CDO',
    enDesc: 'Chief Development Officer',
  },
  // {
  //   src:'uks.jpg', name:"Yonguk",
  //   ko:'최고운영책임자<br/>&nbsp;',
  //   en:'COO',
  //   enDesc: 'Chief Operation Officer',
  // },
  {
    src:'yongho.jpg', name:"Felix",
    ko:'최고기술책임자<br/>&nbsp;',
    en:'CTO',
    enDesc: 'Chief Technical Officer',
  },
  {
    src:'sun.jpg', name:"Sun Koak",
    ko:'최고위협헌팅책임자/<br/>정보보호최고책임자',
    en:'CHO/CISO',
    enDesc: 'Chief Hunting Officer/Chief Information Security Officer',
  },
  {
    src:'m_so.jpg', name:"Myungsub So",
    ko:'Quad Miners<br/>일본 법인장',
    en:'<small>Managing Director<br/>Quad Miners Japan</small>',
  },
];

const TeamList = [
  {
    src:'youngjin.png', name:"Youngjin",
    ko:'엔진 개발자',
    en:'Engine Developer',
  },
  {
    src:'eb.png', name:"EB",
    ko:'프로덕트<br/>디자이너',
    en:'Product Designer',
  },
  // {
  //   src:'jason.jpg', name:"Jason",
  //   ko:'베리사인 미국본사 보안연구원',
  //   en:'VeriSign US Headquarter Security Researcher',
  // },
  {
    src:'jay.png', name:"Jay",
    ko:'해외 영업<br/>&nbsp;',
    en:'Global Sales<br/>&nbsp;',
  },
  {
    src:'taewon.jpg', name:"Taewon",
    ko:'클라우드<br/>개발자',
    en:'Cloud<br/>Developer',
  },
  {
    src:'steve.jpg', name:"Steve",
    ko:'글로벌 오퍼레이션<br/>디렉터',
    en:'Director of<br/>Global Operations',
  },
  {
    src:'rose.jpg', name:"Rose",
    ko:'파이낸스<br/>디렉터',
    en:'Finance<br/>Director',
  },
  {
    src:'philip.jpg', name:"Philip",
    ko:'해외 영업',
    en:'Global Sales<br/>&nbsp;',
  },
  {
    src:'brendon.jpg', name:"Brendon",
    ko:'네트워크<br/>개발자',
    en:'Network<br/>Developer',
  },
  {
    src:'joshua.jpg', name:"Joshua",
    ko:'사이버 보안<br/>전문가',
    en:'Cyber Security<br/>Specialist',
  },
  {
    src:'jasper.jpg', name:"Jasper",
    ko:'FP&amp;A<br/>매니저',
    en:'FP&amp;A<br/>Manager',
  },
  {
    src:'jino.jpg', name:"Jino",
    ko:'인적자원관리자<br/>&nbsp;',
    en:'HR Manager<br/>&nbsp;',
  },
  {
    src:'marcus.jpg', name:"Marcus",
    ko:'테크니컬 리더<br/>&nbsp;',
    en:'Technical<br/>Leader',
  },
  {
    src:'elin.jpg', name:"Elin",
    ko:'품질관리<br/>&nbsp;',
    en:'Quality<br/>Control',
  },
  {
    src:'william.jpg', name:"William",
    ko:'테크니컬 리더<br/>&nbsp;',
    en:'Technical<br/>Leader',
  },
  {
    src:'elvin.jpg', name:"Elvin",
    ko:'데이터통신<br/>엔지니어',
    en:'Data Communications<br/>Engineer',
  },
  {
    src:'tony.jpg', name:"Tony",
    ko:'사이버 보안<br/>전문가',
    en:'Cyber Security<br/>Specialist',
  },
  {
    src:'doyun.jpg', name:"Doyun",
    ko:'보안 엔지니어<br/>&nbsp;',
    en:'Security<br/>Engineer',
  },
  {
    src:'donghan.jpg', name:"David",
    ko:'글로벌채널<br/>세일즈',
    en:'Global Channel<br/>Sales',
  },
  {
    src:'giyong.jpg', name:"Giyong",
    ko:'기술 엔지니어',
    en:'Technical<br/>Engineer',
  },
  {
    src:'heejun.jpg', name:"Heejun",
    ko:'시스템<br/>개발자',
    en:'Systems<br/>Developer',
  },
  {
    src:'jaeyun.jpg', name:"June",
    ko:'기술 엔지니어',
    en:'Technical<br/>Engineer',
  },
  {
    src:'jungguen.jpg', name:"Jungguen",
    ko:'세일즈 매니저',
    en:'Sales<br/>Manager',
  },
  {
    src:'jaesun.jpg', name:"Jaesun",
    ko:'기술 엔지니어',
    en:'Technical<br/>Engineer',
  },
  // {
  //   src:'sungyo.jpg', name:"Sungyo",
  //   ko:'기술 엔지니어',
  //   en:'Technical<br/>Engineer',
  // },
  {
    src:'zerah.jpg', name:"Zerah",
    ko:'기술 엔지니어',
    en:'Technical<br/>Engineer',
  },
  {
    src:'jun.jpg', name:"Jun",
    ko:'사이버 보안<br/>엔지니어',
    en:'Cyber Security<br/>Engineer',
  },
  // {
  //   src:'hyungu.jpg', name:"Daniel",
  //   ko:'프로젝트<br/>매니저',
  //   en:'Project Manager',
  // },
  {
    src:'caravetto.jpg', name:"C.Jay",
    ko:'사이버 보안<br/>엔지니어',
    en:'Cyber Security<br/>Engineer',
  },
  {
    src:'lily.jpg', name:"Lily",
    ko:'사이버 보안<br/>컨설턴트',
    en:'Cyber Security<br/>Consultant',
  },
  {
    src:'ethan.jpg', name:"Ethan",
    ko:'시스템<br/>개발자',
    en:'Systems<br/>Developer',
  },
  {
    src:'emma.jpg', name:"Emma",
    ko:'프론트엔드<br/>웹 개발자',
    en:'Front-End<br/>Web Developer',
  },
  {
    src:'elly.jpg', name:"Elly",
    ko:'브랜드경험<br/>디자이너',
    en:'BX Designer<br/>&nbsp;',
  },
  {
    src:'leo.jpg', name:"Leo",
    ko:'세일즈<br/>매니저',
    en:'Sales<br/>Manager',
  },
  {
    src:'luna.jpg', name:"Luna",
    ko:'세일즈<br/>매니저',
    en:'Sales<br/>Manager',
  },
  {
    src:'levi.jpg', name:"Levi",
    ko:'세일즈<br/>매니저',
    en:'Sales<br/>Manager',
  },
  {
    src:'sangmin.jpg', name:"Steve Lim",
    ko:'글로벌 테크니컬<br/>리더',
    en:'Global Technical<br/>Leader',
  },
  {
    src:'derek.jpg', name:"Derek",
    ko:'데이터보안분석가<br/>&nbsp;',
    en:'Data Security<br/>Analyst',
  },
  {
    src:'jaewon.jpg', name:"Jaewon",
    ko:'총무<br/>&nbsp;',
    en:'General Affairs<br/>&nbsp;',
  },
  {
    src:'carter.jpg', name:"Carter",
    ko:'보안솔루션 팀리더<br/>&nbsp;',
    en:'Head of<br/>Solution Division',
  },
  {
    src:'jin.jpg', name:"Jin",
    ko:'세일즈<br/>매니저',
    en:'Sales<br/>Manager',
  },
  // {
  //   src:'hj.jpg', name:"HJ",
  //   ko:'부사장<br/>&nbsp;',
  //   en:'Vice<br/>President',
  // },
  // {
  //   src:'jonglak_lee.jpg', name:"Prof. Jonglak Lee",
  //   ko:'보안기술 고문<br/>&nbsp;',
  //   en:'Advisor of Security Tech<br/>&nbsp;',
  // },
  // {
  //   src:'chanwoo.png', name:"Chanwoo Baek",
  //   ko:'재정 고문<br/>&nbsp;',
  //   en:'Advisor of Finance<br/>&nbsp;',
  // },
  // {
  //   src:'dominic.jpg', name:"Dominic Lee",
  //   ko:'고문<br/>&nbsp;',
  //   en:'Business Advisor<br/>&nbsp;',
  // },
  // {
  //   src:'qha_steve_kim.png', name:"Qha Steve Kim",
  //   ko:'전략 고문<br/>&nbsp;',
  //   en:'Strategic Advisor<br/>&nbsp;',
  // },
];

const InvestorList = [
  // {
  //   src:'bba.png', name:"Big Bang Angels",
  //   height: '30px',
  //   href: 'http://www.bigbangangels.com/'
  // },
  {
    src:'samyang.jpg', name:"SAMYANG",
    height: '20px',
    href: 'http://www.samyangchem.co.kr/eng/'
  },
  {
    src:'partners_investment.png', name:"Partners Invesetment",
    height: '60px',
    href: 'http://www.partnersi.co.kr/eng/'
  },
  {
    src:'kodit.png', name:"KODIT", // 신용보증기금
    height: '50px',
    href: 'https://www.kodit.co.kr/koditEng/main.do'
  },
  {
    src:'lnsvc.jpg', name:"L&C Venture Capital",
    height: '18px',
    href: 'http://www.lnsvc.co.kr/'
  },
  {
    src:'nau.jpg', name:"NAU IB Capital",
    height: '25px',
    href: 'http://www.nauib.com/en/'
  },
  {
    src:'bridgepole.png', name:"BridgePole Investment Co., Ltd.",
    height: '40px',
    href: 'https://www.bpinvestment.kr/en_index.html'
  },
  {
    src:'kbsecurities.png', name:"KB Securities",
    height: '22px',
    href: 'https://www.kbsec.com/go.able?linkcd=m50030000'
  },
  {
    src:'cjinvestment.png', name:"CJ Investment Inc.",
    height: '40px',
    href: 'http://www.cjinvestment.net/en/'
  },
  {
    src:'quantumventures.png', name:"Quantum Ventures Korea",
    height: '34px',
    href: 'http://www.quantumepk.com/index_en.php'
  },
  {
    src:'shinhanvc.png', name:"Shinhan Venture Investment",
    height: '14px',
    href: 'https://www.shinhanvc.com/en'
  },
  {
    src:'kiwoominvestment.png', name:"Kiwoom Investment",
    height: '40px',
    href: 'http://www.kiwoominvest.com/index.jsp'
  },
];

export { CLevelList, TeamList, InvestorList };