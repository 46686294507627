import Wrapper from 'common/Wrapper';
import 'styles/CaseStudy2.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faBuilding, faDatabase, faServer, faSkull } from '@fortawesome/pro-light-svg-icons';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { faWindow } from '@fortawesome/pro-duotone-svg-icons';

const CaseStudy = () => {
  return (
    <Wrapper active="CaseStudy" lang="ko">
      <div className="CaseStudy2">
        <Intro />
        <Slogan />
        <CaseA />
        <CaseDiagramA />
        <CaseEffectA />
        <CaseB />
        <CaseDiagramB />
        <CaseEffectB />
        <CaseC />
        <CaseDiagramC />
        <CaseEffectC />
        <CaseD />
        <CaseDiagramD1 />
        <CaseDiagramD2 />
      </div>
    </Wrapper>
  )
};

const Intro = () => {
  return (
    <section className="case-intro2">
      <div className="background">
        <canvas id="canvas" />
      </div>
      <div className="container">
        <h2>
          구축사례
        </h2>
      </div>
    </section>
  )
}

const Slogan = () => {
  return(
    <section className="case-slogan2">
      <div className="container">
        <h2>
          Network Blackbox는 고객과의 <em>소중한 경험</em>을 통해,<span className="break-line-auto"/>
          보다 <em>신뢰성</em> 있는 솔루션으로 거듭나고 있습니다.
        </h2>
      </div>
    </section>
  )
}

const CaseA = () => {
  return (
  <section className="case-study2">
    <div className="container">
      <h2>
        <FontAwesomeIcon icon={faBuilding} />&nbsp;
        A사 <em>구축사례</em>
      </h2>
      <ul>
        <li>인사 DB와 연동하여 <em>데이터 유출 방지</em> 및 이직/퇴사 예정자 리스트 확보</li>
        <li>보다 정밀한 <em>바이러스 및 사이버 공격 탐지 내역</em>을 통해 각종 지능형 위협에 대응</li>
      </ul>
    </div>
  </section>
  )
}

const CaseDiagramA = () => {
  return (
  <section className="case-diagram2">
    <div className="container diagram-a">
      <div className="diagram">
        <figure className="line" />
        <figure className="device">
          <figure className="power" />
          <figcaption>SSL Proxy</figcaption>
        </figure>
        <figure className="line" />
        <figure className="device tap">
          <figure className="power" /><figure className="router" />
          <figcaption>TAP</figcaption>
        </figure>
        <figure className="line">
          <figure className="line-left" />
          <figure className="line-right" />
        </figure>
        <figure className="box">
          <figcaption>
            <span>직원망</span>
            <span>공장망</span>
          </figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption>SSL Decryption</figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-right highlight">
          <figcaption>Mirroring</figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="empty" />
        <figure className="device network-blackbox">
          <figure className="power" />
          <figcaption>Network Blackbox</figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="empty" />
        <figure className="arrow arrow-left highlight">
          <figcaption>
            <span>인사 DB</span>
          </figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="line" />
        <figure className="line" />
        <figure className="device siem">
          <figure className="power" />
          <figcaption>NAC</figcaption>
        </figure>
        <figure className="line" />
        <figure className="line" />
      </div>

      <div className="clearfix" />
    </div>
  </section>
  )
}

const CaseEffectA = () => {
  return (
  <section className="case-effect2">
    <div className="container">
      <div className="wrapper">
        <div className="as-is">
          <h4>AS-IS</h4>
          <ul>
            <li>
              <FontAwesomeIcon icon={faServer} />&nbsp;
              <span>배포 서버 차단</span>
            </li>
          </ul>
        </div>
        <div className="to-be">
          <h4>TO-BE</h4>
          <ul>
            <li>
              <FontAwesomeIcon icon={faServer} />&nbsp;
              <span>배포 서버 차단</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faServer} />&nbsp;
              <span>C&amp;C 서버 차단</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faUser} />&nbsp;
              <span>사용자 PC 바이러스 검사</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faUser} />&nbsp;
              <span>사용자 PC 레지스트리 삭제</span>
            </li>
          </ul>
        </div>
      </div>
      <p>
        NAC 장비만으로는 로그만 가지고 있기 때문에 이전 트래픽을 분석할 수 없고, 바이러스 파일을 확보할 수 없는 경우가 많았습니다. 따라서 배포 서버만을 차단하고 있었습니다. Network Blackbox는 실제 발생하는 트래픽을 분석할 수 있으므로, 배포 서버와 C&amp;C 서버를 탐지할 수 있었을 뿐 아니라, 사용자 PC의 이상 행위 또한 탐지하여, 드랍퍼를 발견하고 바이러스 다운로드를 원천 봉쇄할 수 있게 되었습니다.
      </p>
    </div>
  </section>
  )
}

const CaseB = () => {
  return (
  <section className="case-study2">
    <div className="container">
      <h2>
        <FontAwesomeIcon icon={faServer} />&nbsp;
        <span>B사 <em>구축사례</em></span>
      </h2>
      <ul>
        <li><em>망 분리 환경</em>에서 일반 트래픽 및 복호화 트래픽을 100% 수집</li>
        <li>통합로그 솔루션과 연동하여 <em>이상 징후 트래픽 탐지</em></li>
        <li>
          실 트래픽 비교를 통해 기존 솔루션의 <em>정오탐 여부</em>를 판별 (탐지 로그, 패턴 강화, 차단 룰 설정)
        </li>
      </ul>
    </div>
  </section>
  )
}

const CaseDiagramB = () => {
  return (
  <section className="case-diagram2">
    <div className="container diagram-b">
      <div className="diagram">
        <figure className="line" />
        <figure className="device">
          <figure className="power" />
          <figcaption>SSL Proxy</figcaption>
        </figure>
        <figure className="line" />
        <figure className="device tap">
          <figure className="power" /><figure className="router" />
          <figcaption>TAP</figcaption>
        </figure>
        <figure className="line">
          <figure className="line-left" />
          <figure className="line-right" />
        </figure>
        <figure className="box">
          <figcaption>
            <span>IDC #1</span>
          </figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption>SSL Decryption</figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-right highlight">
          <figcaption>Mirroring</figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="empty" />
        <figure className="device network-blackbox">
          <figure className="power" />
          <figcaption>Network Blackbox</figcaption>
        </figure>
        <figure className="line" />
        <figure className="line" />
        <figure className="device siem">
          <figure className="power" />
          <figcaption>SIEM</figcaption>
        </figure>
        <figure className="empty" />
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="arrow arrow-left">
          <figcaption>SSL Decryption</figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-left highlight">
          <figcaption>Mirroring</figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="line" />
        <figure className="device">
          <figure className="power" />
          <figcaption>SSL Proxy</figcaption>
        </figure>
        <figure className="line" />
        <figure className="device tap">
          <figure className="power" /><figure className="router" />
          <figcaption>TAP</figcaption>
        </figure>
        <figure className="line">
          <figure className="line-left" />
          <figure className="line-right" />
        </figure>
        <figure className="box">
          <figcaption>
            <span>IDC #2</span>
          </figcaption>
        </figure>
      </div>
    </div>
  </section>
  )
}

const CaseEffectB = () => {
  return (
  <section className="case-effect2">
    <div className="container">
      <div className="wrapper">
        <div className="as-is">
          <h4>AS-IS</h4>
          <ul>
            <li>
              <FontAwesomeIcon icon={faWindow} />&nbsp;
              <span>웹쉘 공격 탐지</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faServer} />&nbsp;
              <span>보안로그 분석</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faBan} />&nbsp;
              <span>공격자 IP 차단</span>
            </li>
          </ul>
        </div>
        <div className="to-be">
          <h4>TO-BE</h4>
          <ul>
            <li>
              <FontAwesomeIcon icon={faWindow} />&nbsp;
              <span>웹쉘 공격 탐지</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faServer} />&nbsp;
              <span>보안로그 분석</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faWindow} />&nbsp;
              <span>웹쉘 파일 확보, 파일 분석</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faSkull} />&nbsp;
              <span>서버 악성코드 확인 및 제거</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faBan} />&nbsp;
              <span>서버패치 및 공격자 IP 차단</span>
            </li>
          </ul>
        </div>
      </div>
      <p>
        기존 환경에서는 IDS/IPS, SIEM 장비 등에서 위협 행위를 발견하여 공격자 IP를 차단하였지만, 정상 사용자의 행위도 차단되는 경우가 많았습니다. 실제 트래픽을 확인할 수 없었기 때문에, 공격자가 다른 IP를 사용하여 공격하거나 서버 상에 문제가 발생한 경우, 이를 확인하는데 많은 시간이 소요되었습니다. Network Blackbox를 통해 정오탐을 구분하고, 실데이터 확보로 보완하여 네트워크 가시성을 확보하였습니다.
      </p>
    </div>
  </section>
  )
}

const CaseC = () => {
  return (
  <section className="case-study2">
    <div className="container">
      <h2>
        <FontAwesomeIcon icon={faDatabase} />&nbsp;
        C사 <em>구축사례</em>
      </h2>
      <ul>
        <li>수집된 트래픽을 <em>빅데이터 분석솔루션과 연동</em>하여 정보 유출을 탐지</li>
        <li><em>키워드 기반</em>의 이상 행위 탐지 및 <em>기계학습</em>을 통해 각종 위협을 학습하고 대응</li>
      </ul>
    </div>
  </section>
  )
}

const CaseDiagramC = () => {
  return (
  <section className="case-diagram2">
    <div className="container diagram-a">
      <div className="diagram">
        <figure className="line" />
        <figure className="device">
          <figure className="power" />
          <figcaption>SSL Proxy</figcaption>
        </figure>
        <figure className="line" />
        <figure className="device tap">
          <figure className="power" /><figure className="router" />
          <figcaption>TAP</figcaption>
        </figure>
        <figure className="line">
          <figure className="line-left" />
          <figure className="line-right" />
        </figure>
        <figure className="box">
          <figcaption>
            <span>직원망</span>
            <span>기타망</span>
          </figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption>SSL Decryption</figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-right highlight">
          <figcaption>Mirroring</figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="empty" />
        <figure className="device network-blackbox">
          <figure className="power" />
          <figcaption>Network Blackbox</figcaption>
        </figure>
        <figure className="empty" />
        <figure className="empty" />
        <figure className="empty" />
        <figure className="device bigdata">
          <figure className="power" />
          <figcaption>
            <span>빅데이터 분석솔루션</span>
          </figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="empty" />
        <figure className="arrow arrow-right highlight">
          <figcaption>Indexing</figcaption>
        </figure>
        <figure className="empty" />
        <figure className="empty" />
        <figure className="empty" />
        <figure className="arrow arrow-left highlight">
          <figcaption>
            <span>데이터 연동</span>
          </figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <label className="help-text">
          <span>880TB 3개월 트래픽 보관</span>
        </label>
        <figure className="device datanode"><figcaption>DataNode #1</figcaption></figure>
        <figure className="device datanode"><figcaption>DataNode #2</figcaption></figure>
        <figure className="device datanode"><figcaption>DataNode #3</figcaption></figure>
        <figure className="device datanode"><figcaption>DataNode #4</figcaption></figure>
        <figure className="device datanode"><figcaption>DataNode #5</figcaption></figure>
        <figure className="device datanode"><figcaption>DataNode ...</figcaption></figure>
      </div>

      <div className="clearfix" />
    </div>
  </section>
  )
}

const CaseEffectC = () => {
  return (
  <section className="case-effect2">
    <div className="container">
      <p>
        Network Blackbox를 통해 네트워크 트래픽을 100% 수집하고, 빅데이터 분석솔루션과의 연동을 통해 특정 사용자의 행위를 분석하거나 기타 이상 행위를 분석할 수 있도록 수집된 데이터를 가공하는 환경입니다. 대규모 트래픽을 적절하게 로드밸런싱하고 수십억 건의 데이터를 고속으로 처리할 수 있도록 분산 환경을 구성하였습니다.
      </p>
    </div>
  </section>
  )
}

const CaseD = () => {
  return (
  <section className="case-study2">
    <div className="container">
      <h2>
        <FontAwesomeIcon icon={faDatabase} />&nbsp;
        D사 <em>구축사례</em>
      </h2>
      <ul>
        <li>
          다양한 벤더사 제품(웹보안, DLP, 분석솔루션, 네트워크 포렌식)을 <em>Network Blackbox</em> 하나로 통합
        </li>
      </ul>
    </div>
  </section>
  )
}

const CaseDiagramD1 = () => {
  return (
  <section className="case-diagram2">
    <h2 className="before">BEFORE</h2>
    <div className="container diagram-a">
      <div className="diagram">
        <figure className="line" />
        <figure className="line" />
        <figure className="line" />
        <figure className="device">
          <figure className="power" />
          <figcaption>SSL Proxy</figcaption>
        </figure>
        <figure className="line" />
        <figure className="device tap">
          <figure className="power" /><figure className="router" />
          <figcaption>TAP</figcaption>
        </figure>
        <figure className="line" />
        <figure className="line" />
        <figure className="line" />
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="empty" />
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption>SSL Decryption</figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-right highlight">
          <figcaption>Mirroring</figcaption>
        </figure>
      </div>

      <div className="diagram">
        <figure className="empty" />
        <figure className="empty" />
        <figure className="empty" />
        <figure className="empty" />
        <figure className="device">
          <figure className="power" />
          <figcaption style={{fontSize:'13px'}}>
            <span>A사 패킷수집솔루션</span>
          </figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption></figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption></figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption></figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption></figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="device siem">
          <figure className="power" />
          <figcaption style={{fontSize:'13px'}}>
            <span>B사 웹보안</span>
          </figcaption>
        </figure>
        <figure className="empty" />
        <figure className="device siem">
          <figure className="power" />
          <figcaption style={{fontSize:'13px'}}>
            <span>C사 DLP</span>
          </figcaption>
        </figure>
        <figure className="empty" />
        <figure className="device siem">
          <figure className="power" />
          <figcaption style={{fontSize:'13px'}}>
            <span>D사 분석솔루션</span>
          </figcaption>
        </figure>
        <figure className="empty" />
        <figure className="device siem">
          <figure className="power" />
          <figcaption style={{fontSize:'13px'}}>
            <span>E사 네트워크 포렌식</span>
          </figcaption>
        </figure>
      </div>
      <div className="clearfix" />
    </div>
  </section>
  )
}

const CaseDiagramD2 = () => {
  return (
  <section className="case-diagram2">
    <hr/>
    <h2>AFTER</h2>
    <div className="container diagram-d2">
      <div className="diagram">
        <figure className="line" />
        <figure className="line" />
        <figure className="line" />
        <figure className="device">
          <figure className="power" />
          <figcaption>SSL Proxy</figcaption>
        </figure>
        <figure className="line" />
        <figure className="device tap">
          <figure className="power" /><figure className="router" />
          <figcaption>TAP</figcaption>
        </figure>
        <figure className="line" />
        <figure className="line" />
        <figure className="line" />
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="empty" />
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption>SSL Decryption</figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-right highlight">
          <figcaption>Mirroring</figcaption>
        </figure>
      </div>

      <div className="diagram">
        <br/><br/>
        <figure className="empty" />
        <figure className="box">
          <figcaption><img src="/images/common/bb_text_white.png" alt="txt_black"/></figcaption>
        </figure>
        <figure className="device network-blackbox">
          <figure className="power" />
          <figcaption>
            <span>웹보안</span>
          </figcaption>
        </figure>
        <figure className="device network-blackbox">
          <figure className="power" />
          <figcaption>DLP</figcaption>
        </figure>
        <figure className="device network-blackbox">
          <figure className="power" />
          <figcaption>Flow Analysis</figcaption>
        </figure>
        <figure className="device network-blackbox">
          <figure className="power" />
          <figcaption>Network Forensic</figcaption>
        </figure>
      </div>

      <div className="clearfix" />
    </div>
  </section>
  )
}


export default CaseStudy;