import Wrapper from 'common/Wrapper';

const Press = () => {
  return (
    <Wrapper active="Press" lang="ko">
      <section className="history-press">
        <h1>Press Release Archive</h1>
        <div className="container">
          <h4>2024</h4>
          <A2024/>
          <h4>2023</h4>
          <A2023/>
          <h4>2022</h4>
          <A2022 />
          <h4>2021</h4>
          <A2021 />
          <h4>2020</h4>
          <A2020 />
          <h4>2019</h4>
          <A2019 />
          <h4>2018</h4>
          <A2018 />
        </div>
      </section>
    </Wrapper>
  )
};

const A2018 = () => {
  return (
    <div>
      <dl>
        <dt>April 6, 2018</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.ciociso.com/news/articleView.html?idxno=11209" target="_blank">
            제 16차 CISO 정보보호 컨퍼런스 - 360도 보안 분석이 가능한 네트워크 블랙박스
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 15, 2018</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.dailysecu.com/?mod=news&act=articleView&idxno=33090" target="_blank">
            쿼드마이너, 해킹공격 히스토리 추적 '네트워크 블랙박스' 출시
          </a>
        </dd>
      </dl>
      <dl>
        <dt>July 10, 2018</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.newswire.co.kr/newsRead.php?no=872144" target="_blank">
            빅뱅엔젤스, 신용보증기금 Start-up NEST 3기 유망창업기업 포함 2018 유니콘파인더 통합 데모데이 &amp; 엔젤투자 워크샵 개최
          </a>
        </dd>
      </dl>
      <dl>
        <dt>July 13, 2018</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=1G50pp-9ADU" target="_blank">
            Quad Miners x Hong Kong
          </a>
        </dd>
      </dl>
      <dl>
        <dt>September 6, 2018</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.venturesquare.net/768288" target="_blank">
            AAA 이번엔 도쿄로 간다 ‘ReShape Tokyo’ 개최
          </a>
        </dd>
      </dl>
      <dl>
        <dt>September 12, 2018</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=kq_h-tRPuWs" target="_blank">
            Quad Miners x Tokyo
          </a>
        </dd>
      </dl>
      <dl>
        <dt>November 23, 2018</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.etoday.co.kr/news/section/newsview.php?idxno=1691057" target="_blank">
            '빅뱅엔젤스 데모데이' 성료, 우수한 스타트업들 한자리에
          </a>
        </dd>
      </dl>
      <dl>
        <dt>December 10, 2018</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://news.mt.co.kr/mtview.php?no=2018121014507468193" target="_blank">
            [연재] 4차 산업혁명 키워드 '빅데이터', 성장 가능한 유망 창업기업은
          </a>
        </dd>
      </dl>
      <dl>
        <dt>December 13, 2018</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.fntoday.co.kr/news/articleView.html?idxno=174467" target="_blank">
            '쿼드마이너', 빅뱅엔젤스로부터 투자 유치 및 TIPS 프로그램 선정
          </a>
        </dd>
      </dl>
    </div>
  )
}

const A2019 = () => {
  return (
    <div>
      <dl>
        <dt>January 7, 2019</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://sw.tta.or.kr/product/prod_gsce_view.jsp?num=5027" target="_blank">
            쿼드마이너 (넷블랙 2.0) GS인증 1등급 획득
          </a>
        </dd>
      </dl>
      <dl>
        <dt>January 31, 2019</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=xwmTLYh5nTo" target="_blank">
            Network Blackbox Partner Day
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 3, 2019</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.asiatime.co.kr/news/articleView.html?idxno=237815" target="_blank">
            전도유망 스타트업 동남아 시장 진출 도모 … 싱가포르서 ‘리쉐입 싱가포르’행사 열려
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 4, 2019</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=cKHnM9DoJ7A" target="_blank">
            Quad Miners x Singapore
          </a>
        </dd>
      </dl>
      <dl>
        <dt>May 3, 2019</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=te12nROS1gI" target="_blank">
            비즈정보플러스, 쿼드마이너 소개 영상
          </a>
        </dd>
      </dl>
      <dl>
        <dt>May 15, 2019</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.futurekorea.co.kr/news/articleView.html?idxno=117603" target="_blank">
            ㈜쿼드마이너, 차세대 네트워크 탐지 및 대응 솔루션 ‘네트워크 블랙박스’ 개발
          </a>
        </dd>
      </dl>
      <dl>
        <dt>May 30, 2019</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=_CjBIvf6Jhw" target="_blank">
            Quad Miners x CTI LAB Technology Workshop
          </a>
        </dd>
      </dl>
      <dl>
        <dt>June 4, 2019</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.sisanewstime.co.kr/news/articleView.html?idxno=5594" target="_blank">
            사이버 위협으로부터 기업의 소중한 정보를 지키는 수호자
          </a>
        </dd>
      </dl>
      <dl>
        <dt>July 1, 2019</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.youtube.com/watch?v=IImCqJQ58kI" target="_blank">
            Quad Miners x SK infosec 총판 계약 체결식
          </a>
        </dd>
      </dl>
      <dl>
        <dt>July 8, 2019</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://cyber-security.apacciooutlook.com/vendor/quad-miners-the-rising-rookie-in-network-security-cid-4326-mid-262.html" target="_blank">
            Quad Miners - The Rising Rookie in Network Security
          </a>
        </dd>
      </dl>
      <dl>
        <dt>August 13, 2019</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=136433" target="_blank">
            쿼드마이너 ‘네트워크 블랙박스’, 풀 패킷 분석 기반 네트워크 탐지·대응
          </a>
        </dd>
      </dl>
      <dl>
        <dt>August 16, 2019</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=136521" target="_blank">
            쿼드마이너 “세계에서 인정받은 기술력으로 시장 개척”
          </a>
        </dd>
      </dl>
      <dl>
        <dt>September 2, 2019</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.boannews.com/media/view.asp?idx=82629" target="_blank">
            사이버상의 모든 기록 저장소 ‘사이버 블랙박스’의 모든 것
          </a>
        </dd>
      </dl>
      <dl>
        <dt>September 5, 2019</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.venturesquare.net/790846" target="_blank">
            빅뱅엔젤스, 리셰이프 로드쇼 서울 2019 연다
          </a>
        </dd>
      </dl>
      <dl>
        <dt>November 22, 2019</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.edaily.co.kr/news/read?newsId=02637126622688344" target="_blank">
            리노스, 네트워크 블랙박스 카드사 계약…"신사업 본격화"
          </a>
        </dd>
      </dl>
    </div>
  )
}

const A2020 = () => {
  return (
    <div>
      <dl>
        <dt>January 9, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.datanet.co.kr/news/articleView.html?idxno=141348" target="_blank">
            “해외서 인정받은 기술력…글로벌 시장 평정한다”
          </a>
        </dd>
      </dl>
      <dl>
        <dt>January 10, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.datanet.co.kr/news/articleView.html?idxno=141357" target="_blank">
            [2020 ICT 분야별 전망] 보안관제①
          </a>
        </dd>
      </dl>
      <dl>
        <dt>March 20, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.it-b.co.kr/news/articleView.html?idxno=39182" target="_blank">
            인섹시큐리티-쿼드마이너, 공인 파트너ㆍ교육센터 계약 체결
          </a>
        </dd>
      </dl>
      <dl>
        <dt>May 14, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=145878" target="_blank">
            쿼드마이너 ‘클라우드 블랙박스’, 클라우드 트래픽 정밀 분석…멀티 클라우드 보안 강화
          </a>
        </dd>
      </dl>
      <dl>
        <dt>June 1, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.apacbusinessheadlines.com/Toptech-web-version/Quad-Miners/" target="_blank">
            Quad Miners: The Next Generation Network Detection and Response Solution
          </a>
        </dd>
      </dl>
      <dl>
        <dt>July 4, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://kr.aving.net/news/view.php?articleId=1585632" target="_blank">
            빅뱅엔젤스, '유니콘파인더 베이스캠프 2020'...해외 스타트업의 아시아 도약 위한 거점 마련
          </a>
        </dd>
      </dl>
      <dl>
        <dt>July 6, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=147905" target="_blank">
            [차세대 보안관제③] 클라우드 최적화 탐지 기술 등장
          </a>
        </dd>
      </dl>
      <dl>
        <dt>July 13, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=148148" target="_blank">
            ‘차세대 보안 비전 2020’, 오는 22일 열려 … ‘시큐리티 퍼스트’ 전략 공유
          </a>
        </dd>
      </dl>
      <dl>
        <dt>July 13, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.ciokorea.com/news/157726" target="_blank">
            "풀 패킷 분석 '돌풍', 클라우드에서도 이어갑니다" 쿼드마이너 박범중 대표
          </a>
        </dd>
      </dl>
      <dl>
        <dt>July 15, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=148330" target="_blank">
            [차세대 보안 비전 2020 솔루션 프리뷰] 쿼드마이너 ‘네트워크 블랙박스’
          </a>
        </dd>
      </dl>
      <dl>
        <dt>July 22, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=148626" target="_blank">
            [차세대 보안 비전 2020] 고속 네트워크 분석으로 실시간 위협 가시화
          </a>
        </dd>
      </dl>
      <dl>
        <dt>August 26, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.dailysecu.com/news/articleView.html?idxno=112754" target="_blank">
            KISIA·KOTRA, 정보보호 기업 해외진출 위한 온라인 무역지원 사업 전개
          </a>
        </dd>
      </dl>
      <dl>
        <dt>September 2, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.itworld.co.kr/techlibrary/162905" target="_blank">
            “진실을 아는 자만이 대응할 수 있다” 위협 탐지 및 대응의 차세대 해결책, 네트워크 블랙박스
          </a>
        </dd>
      </dl>
      <dl>
        <dt>September 3, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=150278" target="_blank">
            쿼드마이너, 가트너 보고서 NDR 대표 벤더로 등재
          </a>
        </dd>
      </dl>
      <dl>
        <dt>September 29, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=151240" target="_blank">
            [클라우드 보안-관제] 차세대 기술로 클라우드 위협 탐지
          </a>
        </dd>
      </dl>
      <dl>
        <dt>December 3, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.etnews.com/20201203000072" target="_blank">
            쿼드마이너, IBM 손잡고 네트워크 보안 시장 공격 확대
          </a>
        </dd>
      </dl>
      <dl>
        <dt>December 17, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.itworld.co.kr/techlibrary/176389" target="_blank">
            “70여 개 분산된 지점의 트래픽을 모두 분석, 대응한다" 쿼드마이너 네트워크 블랙박스와 IBM 큐레이더의 결합, ‘QUADX’
          </a>
        </dd>
      </dl>
      <dl>
        <dt>December 19, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=154310" target="_blank">
            [2020 결산/보안관제] 복잡한 보안, 오케스트레이션·자동화로 해결
          </a>
        </dd>
      </dl>
      <dl>
        <dt>December 25, 2020</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://networking.apacciooutlook.com/vendor/quad-miners-evidenced-intelligence-cid-5693-mid-416.html" target="_blank">
            Quad Miners: Evidenced Intelligence
          </a>
        </dd>
      </dl>
    </div>
  )
}

const A2021 = () => {
  return (
    <div>
      <dl>
        <dt>January 26, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=155477" target="_blank">
            SK인포섹, 국내 중소 보안기업과 협력 강화
          </a>
        </dd>
      </dl>
      <dl>
        <dt>February 2, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.edaily.co.kr/news/read?newsId=04375526628946584&mediaCodeNo=257" target="_blank">
            보안업계, 협력이 대세…스타트업 손잡고 클라우드 보안 키운다
          </a>
        </dd>
      </dl>
      <dl>
        <dt>February 8, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.inews24.com/view/1341362" target="_blank">
            SK그룹 IT계열사 ESG 경영 '박차'
          </a>
        </dd>
      </dl>
      <dl>
        <dt>March 30, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=157769" target="_blank">
            쿼드마이너, 가트너 ‘NDR 대표 벤더’ 2년 연속 선정
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 5, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=157955" target="_blank">
            [사례연구] 쿼드마이너, 실시간 네트워크 위협 분석으로 본사·지점 완벽 보호
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 6, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.etnews.com/20210406000130" target="_blank">
            [신SW상품대상추천작]쿼드마이너 '넷블랙'
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 8, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://it.chosun.com/site/data/html_dir/2021/04/08/2021040802776.html" target="_blank">
            첫 온라인 RSA 콘퍼런스에 토종 보안기업 출격
          </a>
        </dd>
      </dl>
      <dl>
        <dt>May 10, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=159283" target="_blank">
            쿼드마이너, 글로벌 시장 공략 가속화한다
          </a>
        </dd>
      </dl>
      <dl>
        <dt>May 13, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.boannews.com/media/view.asp?idx=97194" target="_blank">
            [eGISEC 2021 콘퍼런스] 리노스, 제로 트러스트 아키텍처의 완성 - NDR QUADX
          </a>
        </dd>
      </dl>
      <dl>
        <dt>May 17, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.dt.co.kr/contents.html?article_no=2021051702109931820005" target="_blank">
            KISIA, `RSA 2021` 한국공동관 운영…국내 11개 기업 참가
          </a>
        </dd>
      </dl>
      <dl>
        <dt>June 3, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://cloud.apacciooutlook.com/vendor/quad-miners-delivering-a-comprehensive-cloud-security-platform-cid-5866-mid-434.html" target="_blank">
            Quad Miners: Delivering a Comprehensive Cloud Security Platform
          </a>
        </dd>
      </dl>
      <dl>
        <dt>June 7, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="/images/press/datanet_202106.pdf" target="_blank">
            쿼드마이너, 인재확보로 성장속도 높인다
          </a>
        </dd>
      </dl>
      <dl>
        <dt>July 9, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=161706" target="_blank">
            에스엔에이, 쿼드마이너 ‘넷블랙’ 조달 등록…공공 보안 시장 공략 박차
          </a>
        </dd>
      </dl>
      <dl>
        <dt>July 14, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=161860" target="_blank">
            모든 보안 기술 총집합한 위협 탐지와 대응 기술①
          </a>
        </dd>
      </dl>
      <dl>
        <dt>August 6, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=162928" target="_blank">
            “고속 성장하는 NDR, 지난해 매출 22.5% 증가”
          </a>
        </dd>
      </dl>
      <dl>
        <dt>August 18, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.dailysecu.com/news/articleView.html?idxno=127757" target="_blank">
            ICT 기업들, 중국시장 진출 장 ’케이-글로벌@차이나 2021’ 개최…보안기업들도 참가
          </a>
        </dd>
      </dl>
      <dl>
        <dt>September 19, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=164572" target="_blank">
            [포스트 코로나 시대 주목받는 기술] 보안 확장·자동화·통합
          </a>
        </dd>
      </dl>
      <dl>
        <dt>October 5, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=164990" target="_blank">
            [클라우드 보안] 위협 탐지와 대응·보안관제·컨설팅
          </a>
        </dd>
      </dl>
      <dl>
        <dt>October 18, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=165452" target="_blank">
            쿼드마이너, 아시아 최대 국방 전시회 참가
          </a>
        </dd>
      </dl>
      <dl>
        <dt>November 1, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=165985" target="_blank">
            [공급망 공격①] 대규모 피해 양산하는 공급망 공격
          </a>
        </dd>
      </dl>
      <dl>
        <dt>November 9, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://zdnet.co.kr/view/?no=20211109145614" target="_blank">
            한국SW산업협회-코트라, 코리아 IT 엑스포 인 재팬 개최
          </a>
        </dd>
      </dl>
      <dl>
        <dt>December 28, 2021</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=168144" target="_blank">
            쿼드마이너, 인도네시아 정보기관에 NDR 공급
          </a>
        </dd>
      </dl>
    </div>
  )
}

const A2022 = () => {
  return (
    <div>
      <dl>
        <dt>January 18, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.datanet.co.kr/news/articleView.html?idxno=168793" target="_blank">
            쿼드마이너, 기술신용평가기관 최상위 기술기업 인정 받아
          </a>
        </dd>
      </dl>
      <dl>
        <dt>March 30, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=170990" target="_blank">
            쿼드마이너·이글루코퍼레이션, 차세대 보안관제 사업 협력
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 4, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.datanet.co.kr/news/articleView.html?idxno=171131" target="_blank">
            NDR 시장, 지난해 25% 성장…매년 18.5% 성장
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 12, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.dailysecu.com/news/articleView.html?idxno=135827" target="_blank">
            제28회 정보통신망 정보보호 컨퍼런스 2022(NetSec-KR 2022) 개최
          </a>
        </dd>
      </dl>
      <dl>
        <dt>May 12, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.datanet.co.kr/news/articleView.html?idxno=172532" target="_blank">
            KISIA, 정보보호 컨설팅 전문가 양성과정 교육생 모집
          </a>
        </dd>
      </dl>
      <dl>
        <dt>May 15, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.edaily.co.kr/news/read?newsId=01394006632329248&mediaCodeNo=257" target="_blank">
            중기부, 해외 실증 통해 창업기업 해외진출 통로 연다
          </a>
        </dd>
      </dl>
      <dl>
        <dt>May 16, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=172606" target="_blank">
            [APT④] 지속적인 탐지·대응 필수
          </a>
        </dd>
      </dl>
      <dl>
        <dt>May 19, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.boannews.com/media/view.asp?idx=106894" target="_blank">
            KISIA-KOTRA, ‘RSA Conference 2022’ 한국관 운영
          </a>
        </dd>
      </dl>
      <dl>
        <dt>June 9, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=173371" target="_blank">
            쿼드마이너, 시리즈A 투자유치 완료…총 누적투자 103억
          </a>
        </dd>
      </dl>
      <dl>
        <dt>June 9, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.dailysecu.com/news/articleView.html?idxno=137345" target="_blank">
            KISIA-KOTRA, 세계 최대 보안 전시회 ‘RSA Conference 2022’ 한국관 운영
          </a>
        </dd>
      </dl>
      <dl>
        <dt>August 1, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=175042" target="_blank">
            제로 트러스트 원칙으로 내부자 위협 모니터링 필수
          </a>
        </dd>
      </dl>
      <dl>
        <dt>August 23, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=175751" target="_blank">
            쿼드마이너, MDR 시장 출사표…연내 위협헌팅 서비스 개시
          </a>
        </dd>
      </dl>
      <dl>
        <dt>August 24, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.mk.co.kr/news/it/view/2022/08/748543/" target="_blank">
            젊은 학자·스타트업도 UKC서 기회 얻어
          </a>
        </dd>
      </dl>
      <dl>
        <dt>August 30, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=175971" target="_blank">
            쿼드마이너, 포브스 ‘아시아 100대 기업’ 선정
          </a>
        </dd>
      </dl>
      <dl>
        <dt>September 14, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=176343" target="_blank">
            [네트워크 보안⑤] AI 활용해 네트워크 보안 고도화
          </a>
        </dd>
      </dl>
      <dl>
        <dt>October 31, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=177824" target="_blank">
            쿼드마이너, 중기부 '아기유니콘' 선정
          </a>
        </dd>
      </dl>
      <dl>
        <dt>November 14, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=178272" target="_blank">
          로그프레소·쿼드마이너, MDR 서비스 시작
          </a>
        </dd>
      </dl>
      <dl>
        <dt>December 06, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.etnews.com/20221206000142" target="_blank">
          [코리아ICT엑스포in재팬] 韓 ICT 기업, 'DX 기회의 땅' 일본에 사업화 깃발 꽂는다
          </a>
        </dd>
      </dl>
      <dl>
        <dt>December 22, 2022</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.datanet.co.kr/news/articleView.html?idxno=179526" target="_blank">
          “NDR 성장세 지속…성장 동인 ‘클라우드·재택근무’”
          </a>
        </dd>
      </dl>
    </div>
  )
}

const A2023 = () => {
  return (
    <div>
      <dl>
        <dt>January 09, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=179963" target="_blank">
          [ICT 분야별 평가와 전망] XDR·SOAR…통합·자동화로 보안 개선
          </a>
        </dd>
      </dl>
      <dl>
        <dt>February 23, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.mk.co.kr/news/economy/10657110" target="_blank">
          일하기 좋은 일터엔 '존경받는 CEO'가 있다
          </a>
        </dd>
      </dl>
      <dl>
        <dt>February 23, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.mk.co.kr/news/special-edition/10657109" target="_blank">
          압도적 워라밸에 … MZ도 여성도 '매우 만족'
          </a>
        </dd>
      </dl>
      <dl>
        <dt>February 24, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.chosun.com/special/special_section/2023/02/24/HUNET6RWE5BM5N3WJCK3ZNLOZ4/" target="_blank">
          대한민국 GPTW 혁신리더상 35명·6개 팀… 좋은 일터 만들기 선도한다
          </a>
        </dd>
      </dl>
      <dl>
        <dt>February 24, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.hankyung.com/economy/article/2023022371091" target="_blank">
          쿼드마이너, 오전 9~10시 여유시간 제공…본인이 휴가 결재·금요일 정시 퇴근
          </a>
        </dd>
      </dl>
      <dl>
        <dt>March 14, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="www.dt.co.kr/contents.html?article_no=2023031402109931081007" target="_blank">
          파이오링크, 쿼드마이너 NDR 총판 맡아
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 7, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.boannews.com/media/view.asp?idx=116914&page=1&kind=3" target="_blank">
          KISIA, 국내 정보보호 기업의 일본 시장 진출 성공적으로 이끈다
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 7, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=182476" target="_blank">
          쿼드마이너, 가트너 NDR 보고서 4년 연속 대표 벤더 등재
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 10, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=182487" target="_blank">
          NDR, 2026년까지 14.1% 성장…AI 활용 필수
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 10, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=182488" target="_blank">
          진행중인 공격 찾는 ‘위협 헌팅’…선제방어 강화
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 21, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=182846" target="_blank">
          한국 보안기업, RSA 컨퍼런스서 기술 경쟁력 입증한다
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 24, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://m.ddaily.co.kr/m/m_article/?no=262482" target="_blank">
          [RSAC2023] 세계 최대 사이버보안 행사, 올해 주제는 '함께하면 강해진다
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 27, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.boannews.com/media/view.asp?idx=117563" target="_blank">
          [RSAC 2023] 쿼드마이너 “NDR 분야 네트워크 풀 패킷 기술력으로 승부”
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 28, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.ddaily.co.kr/page/view/2023042804561031498" target="_blank">
          [RSAC2023] 쿼드마이너, NDR로 글로벌 시장 진출 속도
          </a>
        </dd>
      </dl>
      <dl>
        <dt>June 5, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://ddaily.co.kr/page/view/2023060510241889165" target="_blank">
          KISIA, 정보보호산업 인재 양성 과정 교육생 모집
          </a>
        </dd>
      </dl>
      <dl>
        <dt>June 27, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.datanet.co.kr/news/articleView.html?idxno=184716" target="_blank">
          쿼드마이너, 인도네시아 정보기관에 NDR 공급
          </a>
        </dd>
      </dl>
      <dl>
        <dt>August 2, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://news.mt.co.kr/mtview.php?no=2023072717552761224" target="_blank">
          "日 디지털 전환은 절호의 기회…한번 뚫으면 보안 금맥 터진다"
          </a>
        </dd>
      </dl>
      <dl>
        <dt>August 29, 2023</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.datanet.co.kr/news/articleView.html?idxno=186669" target="_blank">
          파이오링크·쿼드마이너, 제로 트러스트 보안 사업 맞손
          </a>
        </dd>
      </dl>
      <dl>
        <dt>September 25, 2023</dt>
        <dd>
          <a
            rel="noreferrer noopener"
            href="https://www.news1.kr/articles/?5182780"
            target="_blank"
          >
            대전 폴리텍대-클라우드·정보보안 기업 14곳 '인재양성' 협약
          </a>
        </dd>
      </dl>
      <dl>
        <dt>September 18, 2023</dt>
        <dd>
          <a
            rel="noreferrer noopener"
            href="https://www.datanet.co.kr/news/articleView.html?idxno=187275"
            target="_blank"
          >
            [네트워크타임즈 창간 30주년 축사] 박범중 쿼드마이너 대표
          </a>
        </dd>
      </dl>
      <dl>
        <dt>September 11, 2023</dt>
        <dd>
          <a
            rel="noreferrer noopener"
            href="https://www.fnnews.com/news/202309111518016231"
            target="_blank"
          >
            국방혁신기술 보안협회 창립 총회
          </a>
        </dd>
      </dl>
      <dl>
        <dt>September 1, 2023</dt>
        <dd>
          <a
            rel="noreferrer noopener"
            href="https://ddaily.co.kr/page/view/2023090111302079986"
            target="_blank"
          >
            KISIA, 국방보안콘퍼런스 부대행사로 보안 제품 전시회 개최
          </a>
        </dd>
      </dl>
      <dl>
        <dt>September 7, 2023</dt>
        <dd>
          <a
            rel="noreferrer noopener"
            href="https://www.ciokorea.com/tags/228007/QUAD+XDR/306421"
            target="_blank"
          >
            쿼드마이너 ‘QUAD XDR’이 실현하는 제로 트러스트 보안 운영 자동화
          </a>
        </dd>
      </dl>
      <dl>
        <dt>October 12, 2023</dt>
        <dd>
          <a
            rel="noreferrer noopener"
            href="https://www.datanet.co.kr/news/articleView.html?idxno=187752"
            target="_blank"
          >
            [제로 트러스트⑦] 통합 탐지·대응 플랫폼으로 ‘지속적 평가’
          </a>
        </dd>
      </dl>
      <dl>
        <dt>October 26, 2023</dt>
        <dd>
          <a
            rel="noreferrer noopener"
            href="https://www.datanet.co.kr/news/articleView.html?idxno=188241"
            target="_blank"
          >
            쿼드마이너, 시리즈B 150억 투자 유치 완료
          </a>
        </dd>
      </dl>
      <dl>
        <dt>October 31, 2023</dt>
        <dd>
          <a
            rel="noreferrer noopener"
            href="https://kr.investing.com/news/markets/article-959638"
            target="_blank"
          >
            해킹 위협 증가에 급성장하는 ‘사이버 보안산업’…관련 스타트업 성장기대감↑
          </a>
        </dd>
      </dl>
      <dl>
        <dt>November 8, 2023</dt>
        <dd>
          <a
            rel="noreferrer noopener"
            href="https://www.mk.co.kr/news/business/10869567"
            target="_blank"
          >
            고속성장 비결은 정부·중기 '원팀 R&D'
          </a>
        </dd>
      </dl>
      <dl>
        <dt>November 26, 2023</dt>
        <dd>
          <a
            rel="noreferrer noopener"
            href="https://www.etnews.com/20231124000028"
            target="_blank"
          >
            [2023 하반기 대한민국 정보보호제품 혁신대상]정보보안-쿼드마이너 '네트워크 블랙박스'
          </a>
        </dd>
      </dl>
      <dl>
        <dt>November 28, 2023</dt>
        <dd>
          <a
            rel="noreferrer noopener"
            href="https://www.datanet.co.kr/news/articleView.html?idxno=189112"
            target="_blank"
          >
            박범중 쿼드마이너 대표, 마약 근절 캠페인 ‘노엑시트’ 동참
          </a>
        </dd>
      </dl>
      <dl>
        <dt>December 6, 2023</dt>
        <dd>
          <a
            rel="noreferrer noopener"
            href="https://www.etnews.com/20231206000143"
            target="_blank"
          >
            [2023 하반기 대한민국 정보보호제품 혁신대상] 시큐리온 · 쿼드마이너 · 스탠스 · 엣지디엑스 대상 수상
          </a>
        </dd>
      </dl>
      <dl>
        <dt>December 29, 2023</dt>
        <dd>
          <a
            rel="noreferrer noopener"
            href="https://www.datanet.co.kr/news/articleView.html?idxno=189963"
            target="_blank"
          >
            쿼드마이너, 중소벤처기업부 ‘스케일업 팁스’ 프로그램 선정
          </a>
        </dd>
      </dl>
    </div>
  )
};

const A2024 = () => {
  return (
    <div>
      <dl>
        <dt>January 16, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.businessreport.kr/news/articleView.html?idxno=41472" target="_blank">
            [Hi-Start-Up][쿼드마이너] “NDR 분야 네트워크 풀 패킷 기술력으로 승부”
          </a>
        </dd>
      </dl>
      <dl>
        <dt>February 19, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.denews.co.kr/news/articleView.html?idxno=28571" target="_blank">
            인성디지탈, 쿼드마이너의 차세대 네트워크 보안 솔루션 공급
          </a>
        </dd>
      </dl>
      <dl>
        <dt>February 20, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="http://www.securityfact.co.kr/news/view.php?no=4834" target="_blank">
            쿼드마이너, 국내 NDR·XDR 보안시장 공략 본격화
          </a>
        </dd>
      </dl>
      <dl>
        <dt>February 21, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.mk.co.kr/news/special-edition/10947786" target="_blank">
            쿼드마이너, 신뢰·상호존중 문화 위해 노력
          </a>
        </dd>
      </dl>
      <dl>
        <dt>March 6, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.datanet.co.kr/news/articleView.html?idxno=191502" target="_blank">
            쿼드마이너, 정보보안경영시스템 국제 표준인증 획득
          </a>
        </dd>
      </dl>
      <dl>
        <dt>March 6, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://zdnet.co.kr/view/?no=20240306103041" target="_blank">
            기업 내부 신뢰 구간 아냐…"사이버 위협 증거 모두 수집-분석하라"
          </a>
        </dd>
      </dl>
      <dl>
        <dt>March 12, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://weeklytrade.co.kr/news/view.html?section=1&category=5&item=&no=90884" target="_blank">
            수출바우처 참여기업 수출증가율 무려 120%
          </a>
        </dd>
      </dl>
      <dl>
        <dt>March 26, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://prtimes.jp/main/html/rd/p/000000069.000018682.html" target="_blank">
            サイバー<wbr/>セキュリティ<wbr/>カンパニーの<wbr/>フーバー<wbr/>ブレイン、Quad Miners Co., Ltd.との<wbr/>国内<wbr/>展開<wbr/>戦略に<wbr/>基づき<wbr/>日本法人<wbr/>クワッド<wbr/>マイナー<wbr/>ジャパンを<wbr/>グループ会社へ
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 4, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://zdnet.co.kr/view/?no=20240404142224" target="_blank">
            K-글로벌 프로젝트 기업 "성장 쑥~"
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 9, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.newsway.co.kr/news/view?ud=2024040915134862711" target="_blank">
            핀테크 투자 생태계 활성화 나선 금융위···'혁신펀드 5천억원 추가 조성'
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 18, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.datanet.co.kr/news/articleView.html?idxno=192766" target="_blank">
            제로 트러스트 환경을 위한 보안운영 자동화 방안
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 18, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.datanet.co.kr/news/articleView.html?idxno=192765" target="_blank">
            쿼드마이너 ‘네트워크 블랙박스’, 위협에 대한 실질적 대응 지원
          </a>
        </dd>
      </dl>
      <dl>
        <dt>April 24, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.datanet.co.kr/news/articleView.html?idxno=193007" target="_blank">
            KISIA, 한일 정보보호 기업 교류회 열며 일본 진출 지원
          </a>
        </dd>
      </dl>
      <dl>
        <dt>May 7, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.dt.co.kr/contents.html?article_no=2024050702109963077008" target="_blank">
            신용보증기금, `제11기 혁신아이콘` 5개社 지원
          </a>
        </dd>
      </dl>
      <dl>
        <dt>May 9, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.datanet.co.kr/news/articleView.html?idxno=193460" target="_blank">
            쿼드마이너, AI/XAI 보안 기술 개발 사업 수주
          </a>
        </dd>
      </dl>
      <dl>
        <dt>May 29, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.dt.co.kr/contents.html?article_no=2024052902109923063003" target="_blank">
            한국능률협회컨설팅, `SW고성장클럽` 지원 사업으로 우리 기업의 글로벌 경쟁력 강화 견인
          </a>
        </dd>
      </dl>
      <dl>
        <dt>June 19, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.news2day.co.kr/article/20240619500132" target="_blank">
            KISA 경기정보보호지원센터-시큐리티플러스, ‘PLUS2024 보안 컨퍼런스’ 성료
          </a>
        </dd>
      </dl>
      <dl>
        <dt>June 26, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://m.boannews.com/html/detail.html?idx=130853" target="_blank">
            진앤현시큐리티-쿼드마이너-에이아이스페라, 업무역량 강화 위해 MOU 체결
          </a>
        </dd>
      </dl>
      <dl>
        <dt>July 24, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.dt.co.kr/contents.html?article_no=2024072402109931092007" target="_blank">
            KISIA, 제19회 국방보안 정보보호 제품 전시회 개최
          </a>
        </dd>
      </dl>
      <dl>
        <dt>July 24, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.itworld.co.kr/news/345349" target="_blank">
            사례를 통해 알아보는 풀 패킷 전수 조사 기반의 '설명가능한' 보안 대응 전략
          </a>
        </dd>
      </dl>
      <dl>
        <dt>July 25, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.boannews.com/media/view.asp?idx=131605" target="_blank">
            KAIST 사이버보안연구센터, 기업 대상 정보보안 핵심원천기술 교육 실시
          </a>
        </dd>
      </dl>
      <dl>
        <dt>August 5, 2024</dt>
        <dd>
          <a rel="noreferrer noopener" href="https://www.prnewswire.com/apac/news-releases/cyberdsa-2024-the-premier-cybersecurity-event-returns-to-kuala-lumpur-302214145.html" target="_blank">
            CyberDSA 2024: The Premier Cybersecurity Event Returns to Kuala Lumpur
          </a>
        </dd>
      </dl>
    </div>
  )
};

export default Press;