import Wrapper from "common/Wrapper";
import "styles/ContactUs.scss";

const ContactUs = () => {
  return (
    <Wrapper active="ContactUs" lang="en">
      <section className="cu-intro">
        <div className="background">
          <img
            src="/images/contactus/together.svg"
            alt="We'd love to hear from you."
          />
        </div>
        <div className="btn-wrap">
          <div className="btn-box">
            <a
              rel="noreferrer noopener"
              href="https://sales.quadminers.com/hc/en-us/requests/new"
              target="_blank"
            >
              <span className="btn inquiry">Sales Contact</span>
              <span>Sales and Simple Product Inquiries</span>
            </a>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default ContactUs;
