import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Wrapper from "common/Wrapper";
import "styles/ContactUs.scss";

const HelpDesk = () => {
  return (
    <Wrapper active="HelpDesk" lang="en">
      <section className="cu-intro">
        <div className="background">
          <img
            src="/images/contactus/together.svg"
            alt="We'd love to hear from you."
          />
        </div>
        <div className="btn-wrap">
          <div className="btn-box">
            <a
              rel="noreferrer noopener"
              href="http://desk.quadminers.com"
              target="_blank"
            >
              <span className="btn support">Customer Technical Support</span>
              <span>Customer and Partner Technical Support</span>
            </a>
          </div>
          <div className="btn-link">
            <a
              rel="noreferrer noopener"
              href="https://www.quadminers.com/etc/helpdesk/qm_global_helpdesk_guide_en.pdf"
              target="_blank"
            >
              Download the Global HelpDesk User's Guide{" "}
              <FontAwesomeIcon icon={faDownload} />
            </a>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default HelpDesk;
