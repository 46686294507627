import { Link } from 'react-router-dom';
import Wrapper from 'common/Wrapper';
import Curve from 'common/Curve';
import 'styles/NetworkBlackbox.scss';
import React, { useRef, useEffect, useState } from 'react';
import $ from "jquery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faSearch, faAnalytics, faFileChartLine, faExclamationCircle, faLongArrowRight } from "@fortawesome/pro-light-svg-icons";
import { ReactComponent as Mirroring } from "images/network_blackbox/mirroring.svg";
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';

const NetworkBlackbox = () => {
  return (
    <Wrapper active="Blackbox" lang="en">
      <Intro />
      <Curve />
      <Slogan />
      <Chain />
      <Movie />
      <FullPacket />
      <Packet />
      <Legacy />
      <Detection />
      <ThreatResponse />
      <Analysis />
      <Ai />
      <Screenshots />
      <Patent />
      <GsCertificate />
      <Contactus />
    </Wrapper>
  )
};

const Intro = () => {
  const [isMount, setIsMount] = useState(false);
  const smoke = new Image();
  smoke.src = '/images/network_blackbox/smoke.png';

  const opts = {
    x: 100,
    y: 0,
    size: 600,
    particles: 100,
    speed: {
      x: -1,
      y: 2.5,
      fade: 100,
      acceleration: 200
    }
  };
  let particles = [], canvases = [];

  useEffect(()=>{
    setIsMount(true)
    let particle = function(canvas) {
      let x, y, size, speedX, speedY, opacity;
      reset();

      this.update = function(){
        if(opacity > 0){
          opacity -= (Math.random() / opts.speed.fade);
        }
        (opacity <= 0) && reset();

        speedX -= Math.random() / opts.speed.acceleration;
        speedY -= Math.random() / opts.speed.acceleration;
        x += speedX;
        y += speedY;
        size += Math.random();
        drawParticle(x, y, size, opacity);
      };

      function drawParticle(x, y, size, opacity) {
        canvas.globalAlpha = opacity;
        canvas.drawImage(smoke, 0, 0, opts.size, opts.size, x, y, size, size);
      }

      function reset(){
        x = opts.x;
        y = opts.y;
        size = opts.size;
        speedX = Math.random() * opts.speed.x;
        speedY = Math.random() * opts.speed.y;
        opacity = Math.random();
      }
    };
    let canvas = function(el){
      const canvas = el[0].getContext('2d');
      canvas.width = el.width();
      canvas.height = el.height();

      for(let c = 0; c < opts.particles; c++){
        particles.push(new particle(canvas));
      }

      this.clear = function(){
        canvas.clearRect(0, 0, canvas.width, canvas.height);
      };
    };
    canvases.push(
      new canvas( $('#back-canvas') )
    );

    function render(){
      canvases.forEach(function(canvas){
        canvas.clear();
      });
      particles.forEach(function(particle){
        particle.update();
      });
      isMount && window.requestAnimationFrame(render);
    }
    render();

    return () => {
      setIsMount(false)
    };
  });
  return (
    <section className="bb-intro">
      <div className="background">
        <canvas id="back-canvas" width="100%" height="100%" />
      </div>
      <div className="container">
        <h2>
          <span className="sr-only">Network Blackbox</span>
          <div>
            <img src="/images/common/bb2_text_white.png"
              alt="Network Blackbox logo" />
          </div>
          <span className="avant-garde">Cyber Threat Hunting Solution</span>
        </h2>
      </div>
      <div className="hero">
        <img src="/images/network_blackbox/version3_1.jpg"
          alt="Network Blackbox" />
      </div>
    </section>
  )
};

const Slogan = () => {
  return(
  <section className="bb-slogan">
    <div className="container">
      <h2> Completion of Zero Trust Architecture </h2>
    </div>
  </section>
  )
};

const Chain = () => {
  const containerRef = useRef();

  useEffect(()=>{
    $(()=>{
      // 가장 높이가 높은 DIV를 찾자
      let maxHeight = 0;
      $(containerRef.current).find('li>div').each(function(){
        let h = $(this).height();
        maxHeight = (maxHeight < h) ? h: maxHeight;
      });

      // 높이를 다 똑같게 만들자
      $(containerRef.current).find('li>div').each(function(){
        $(this).css('min-height', maxHeight);
      });
    });
  });
  return (
    <section className="bb-chain">
      <div className="container" ref={containerRef}>
        <ul>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon icon={faDatabase}
                  style={{color:'#adb5bd'}} /> Collect
              </h4>
              It stores network traffic as they are without any loss of data on a maximum of the 40G network.
            </div>
          </li>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon icon={faSearch}
                  style={{color:'#adb5bd'}} /> Hunting
              </h4>
              Establish a framework from the TTPs perspective to hunt threats.<br /><br />
            </div>
          </li>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon icon={faAnalytics}
                  style={{color:'#adb5bd'}} /> XSec
              </h4>
              Provides evidence of specific attacks in an infringement accident.
            </div>
          </li>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon icon={faFileChartLine}
                  style={{color:'#adb5bd'}} /> Response
              </h4>
              It informs you of the threat actions that have occurred through various channels.
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
};

const Movie = () => {
  return (
    <section className="bb-movie">
      <div className="container">
        <div className="video-container">
          <iframe title="movie" width="853" height="480"
            src={`https://www.youtube.com/embed/6rGqrPpripU`}
            frameBorder="0"
            allowFullScreen={true}
          />
        </div>
      </div>
    </section>
  )
};

const FullPacket = () => {
  return (
    <section className="bb-full-packet">
      <div className="container">
        <h2> 100% Full Packet Capture </h2>
        <ul>
          <li>
            <div>
              <h4>40Gbps</h4>
              Connectivity
            </div>
          </li>
          <li>
            <div>
              <h4>100%</h4>
              Zero Packet Loss
            </div>
          </li>
          <li>
            <div>
              <h4>Payload</h4>
              Save up to 15PB
            </div>
          </li>
        </ul>
        <div className="mirroring">
          <div>
            <Mirroring />
          </div>
          <p>
            ※&nbsp; We collect packets using a mirroring method, while there is no impact to the network.
          </p>
        </div>
      </div>
    </section>
  )
};

export function Packet() {
  return(
   <section className="bb-packet-capture">
     <div className="container">
       <h2> Recombining network packets in real time </h2>
       <p>
          It does not simply store packets,<span className="break-line-auto" />
          but instead <em>recombines up to the range of Application Layer (L7) </em>
          to visualize what’s happening on the network.
       </p>
     </div>
     <div className="stream">
       <img src="/images/network_blackbox/stream.svg" alt="Packet Stream" />
       <div className="stream-anim">
         <div>1011010101010101010101010101010101010101010101010100101010101010101010101010101010101010101010101010110110101010101010101010101010101010101010101010101001010101010101010101010101010101010101010101010101</div>
       </div>
     </div>
     <div className="stream-container">
       <p>
         ※&nbsp;
         In addition, it performs various post-processing tasks such as virus scan, detection of abnormal actions, and etc.
       </p>
     </div>
   </section>
 )
}

const Legacy = () => {
  return(
    <section className="bb-legacy">
      <div className="container">
        <ul>
          <li>Physical</li>
          <li>Data Link</li>
          <li>Network</li>
          <li>Transport</li>
          <li>Session</li>
          <li>Presentation</li>
          <li>Application</li>
        </ul>
        <p>
          It visualizes traffic from <em>all network layers.</em><span className="break-line-auto invert" />
        </p>
      </div>
    </section>
  )
};

export function Detection() {
  return(
    <section className="bb-detection">
      <div className="container">
        <h2> Real-time intelligent threat detection </h2>
        <p>
        It uses the newest threat detection rules to detect unusual signs in real time.
        </p>
        <ul>
          <li>
            <div>
              <h4>25000+</h4>
              Cyber Threat Hunting
            </div>
          </li>
          <li>
            <div>
              <h4>Realtime</h4>
              Anomaly Detection
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
};

export function ThreatResponse() {
  const containerRef = useRef();

  useEffect(()=>{
    // 가장 높이가 높은 DIV를 찾자
    let maxHeight = 0;
    $(containerRef).find('li>div').each(function(){
      let h = $(this).height();
      maxHeight = (maxHeight < h) ? h: maxHeight;
    });
    // 높이를 다 똑같게 만들자
    $(containerRef).find('li>div').each(function(){
      $(this).css('min-height', maxHeight);
    });
  });

  return(
    <section className="bb-threat-response">
      <div className="container" ref={containerRef}>
        <h2>
          <FontAwesomeIcon icon={faExclamationCircle}
            style={{color:'#f03e3e'}} /> Responding to highly sophisticated threats
        </h2>
        <ul>
          <li>
            <div> Signature-based attack detection </div>
          </li>
          <li>
            <div> Behavior-based attack detection </div>
          </li>
          <li>
            <div> C&C, malware and worm detection </div>
          </li>
          <li>
            <div> Scenario-based attack detection </div>
          </li>
        </ul>
        <p> It provides clear visibility malware by analyzing the detected details gathered from various angles. </p>
      </div>
    </section>
  )
}

export function Analysis() {
  return(
    <section className="bb-analysis">
      <div className="container">
        <h2> In-depth analysis of network traffic </h2>
        <p>
          More than simply analyzing for cyber threats, it checks the actual packets, not just logs, through its highly refined Database.
        </p>

        <div className="tables">
          <div className="left">
            <div className="table-database">
              <h5> More than 20 variations of data analysis sets </h5>
              <table>
              <tbody>
              <tr>
                <td><FontAwesomeIcon icon={faDatabase} className="fa-3x" /></td>
                <td><FontAwesomeIcon icon={faDatabase} className="fa-3x" /></td>
                <td><FontAwesomeIcon icon={faDatabase} className="fa-3x" /></td>
                <td><FontAwesomeIcon icon={faDatabase} className="fa-3x" /></td>
                <td style={{fontSize:'10px'}}>
                  <FontAwesomeIcon icon={faDatabase} className="fa-3x" />
                </td>
                <td style={{fontSize:'10px'}}>
                  <FontAwesomeIcon icon={faDatabase} className="fa-3x" />
                </td>
              </tr>
              <tr>
                <td>Payload</td>
                <td>Flow</td>
                <td>Contents</td>
                <td>Rules</td>
                <td>...</td>
                <td>...</td>
              </tr>
              </tbody>
              </table>
              <h4> Correlation analysis with more than 80 variations of conditions </h4>
            </div>
            <div className="table-database">
              <table>
              <tbody>
              <tr>
                <td><FontAwesomeIcon icon={faDatabase} className="fa-3x" /></td>
                <td><FontAwesomeIcon icon={faDatabase} className="fa-3x" /></td>
              </tr>
              <tr>
                <td>Stats</td>
                <td>Event</td>
              </tr>
              </tbody>
              </table>
              <h4>
                Threshold-based analysis<span className="break-line-auto" />
                and analysis of events collected
                from multiple devices
              </h4>
            </div>
          </div>
          <div className="right">
            <img src="/images/network_blackbox/scenario.svg" alt="Scenario" />
          </div>
        </div>
      </div>
    </section>
  )
}

export function Ai() {
  return (
    <section className="bb-ai">
      <div className="container">
        <h2> Machine Learning based AI Analysis Engine </h2>
        <p>
        It precisely runs detections by learning detected events as well as various behaviors that occur on the network. It is a machine learning based AI solution that is able to explain reasons and causes of the results.
        </p>
      </div>
      <div className="container-picture">
        <ul className="circles">
          <li>
            <div>Flow</div>
          </li>
          <li>
            <div>Statistics</div>
          </li>
          <li>
            <div>Content</div>
          </li>
          <li>
            <div>Event</div>
          </li>
        </ul>
        <div className="head">
          <img src="/images/cloud_blackbox/head-side-brain.svg" alt="Head" />
          <div>
            NBA<br/>
            Algorithm
          </div>
        </div>
        <div className="threat">
          <div>
            Responding<br/>
            to highly<br/>
            sophisticated<br/>
            threats
          </div>
        </div>
      </div>
    </section>
  )
}

const Screenshots = () => {
  const [modalOn, setModalOn] = useState(false);
  const [img, setImg] = useState();
  function imgClick(e){
    setImg(e.currentTarget.attributes.src.nodeValue)
    setModalOn(!modalOn);
  }
  function closeModal(){
    setModalOn(!modalOn);
  }
  return (
    <section className="bb-screenshots2">
      <div className="container">
        <div>
          <div><img onClick={imgClick} src="/images/screenshot/sign_in.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/daily_learn_graph.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/asset_management.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/earth.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/ip_profile.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/scenario.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/meta_search.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/th_rule.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/content.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/util.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/detail_view.jpg" alt="" /></div>
        </div>
      </div>
      {modalOn && 
        <div className='modal-container' onClick={closeModal}>
          <div className='modal'>
            <img src={img} alt="" />
            <span className='close-btn' onClick={closeModal}>
            <FontAwesomeIcon icon={faCircleXmark} />
            </span>
          </div>
        </div>
      }
    </section>
  )
}

const GsCertificate = () => {
  function range(min, max) {
    return Math.round(Math.random() * (max - min) + min);
  }
  function makeArr() {
    let arr = [];
    for(var i=0; i<50; i++) {
      arr.push({
        left: `${range(1, 1600)}px`,
        width: `${range(1, 10)}px`,
        height: `${range(1, 10)}px`,
        animationDelay: `${Math.random()*2}s`,
        backgroundColor: `rgb(${range(1,255)}, ${range(1,255)}, ${range(1,255)})`
      });
    }
    return arr;
  }
  return (
    <section className="bb-gs">
      <div className="confetti__container">
        {makeArr().map((d, i)=>(
          <div key={i} className={`confetti confetti--${
            ['fast', 'medium', 'slow'][range(0, 2)]
          }`} style={d} />
        ))}
      </div>
      <div className="container">
        <h4>
          <img src="/images/network_blackbox/gs-cert.png"
            alt="GS Certificate mark"
            style={{
              height:'45px',
              verticalAlign:'middle'
            }} />&nbsp;
          &nbsp; GS Certification Level 1
        </h4>
        <p>
        Network Blackbox, a Cyber Threat Security solution has received GS Certified Level 1.
        </p>
        <p className="text-center">
          Network Blackbox 3.0<br/>
          Certification Number &nbsp;21-0562
        </p>
      </div>
    </section>
  )
};

const Patent = () => {
  return(
    <section className="bb-patent">
      <div className="container">
        <h2> Patent applications in South Korea </h2>
        <dl>
          <dd>
          A high-performance packet stream storage system and its method using the system
            &nbsp;<small>(10–2080477)</small>
          </dd>
        </dl>
        <dl>
          <dd>
          A pattern-based index processing system and its method using the system
            &nbsp;<small>(10–2080478)</small>
          </dd>
        </dl>
        <dl>
          <dd>
          A scenario-centered real-time attack detection system and its method using the system
            &nbsp;<small>(10–2080479)</small>
          </dd>
        </dl>
      </div>
      <div className="container">
        <h2> International patent application (PCT, United States of America, Japan) </h2>
        <dl>
          <dd>
          A network forensic system and its method using the system
            &nbsp;<small>(PCT-KR2019-008860)</small>
          </dd>
        </dl>
      </div>
      <div className="container">
        <h2> Procurement Registration Product </h2>
        <dl>
          <dd>
            <a rel="noreferrer noopener" href="https://digitalmall.g2b.go.kr:8058/dm/pp/goods/dmPpCsGoodsDtlInfo.do?cntrctGoodsMngNo=002161674001&pageTy=null&ctgrDivCd=CS&cntrctGoodsLclId=44&cntrctGoodsMclId=4405" target="_blank">
            Communication Software, Quad Miners, Network Blackbox 2.0, and Network Analysis Solution
            </a>
          </dd>
        </dl>
      </div>
    </section>
  )
}

export function Contactus() {
  return(
    <section className="bb-contactus">
      <div className="container">
        <ul>
          <li>
            <h4>Quotation and Technical Q&A Contact</h4>
            <br/><br/>
            <Link to="/en/contact_us.html">
              <FontAwesomeIcon
                icon={faLongArrowRight} /> Learn more
            </Link>
          </li>
        </ul>
      </div>
    </section>
  )
}
export default NetworkBlackbox;


