import { Link } from 'react-router-dom';
import Wrapper from 'common/Wrapper';
import Curve from 'common/Curve';
import 'styles/NetworkBlackbox2.scss';
import React, { useRef, useEffect, useState } from 'react';
import $ from "jquery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faSearch, faAnalytics, faFileChartLine, faLongArrowRight} from "@fortawesome/pro-light-svg-icons";
import { faCircleXmark, faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { ReactComponent as Mirroring } from "images/network_blackbox/mirroring.svg";
// import { ReactComponent as HowSvg } from "images/network_blackbox/how_it_works.svg";

const NetworkBlackbox = () => {
  return (
    <Wrapper active="Blackbox" lang="ko">
      <div className="NetworkBlackbox2">
        <Intro />
        <Chain />
        <CoreTech />
        <Title />
        <Video />
        <Packet />
        <Detection />
        <ThreatResponse />
        <Analysis />
        <Ai />
        {/*<Screenshots />*/}
        <Patent />
        {/*<GsCertificate />*/}
        <Contactus />
      </div>
    </Wrapper>
  )
};

const Intro = () => {
  return (
    <section className="nbb4_ares">
      <div className="wrapper">
        <div className="bg" />
        <div className="text">
          4.0
        </div>
        <div className="char">
          <img src="/images/network_blackbox4/ares_char.webp" alt="Ares" />
        </div>
        <div className="logo">
          <img src="/images/common/bb2_text_white.png"
            alt="Network Blackbox logo" />
        </div>
      </div>
    </section>
  )
}

const Chain = () => {
  return (
    <section className="nbb_4_chain">
      <h2>
        <strong>풀패킷 기반</strong>의 사이버 위협 헌팅 솔루션
      </h2>
      <div className="position">
        <div className="wrapper">
          <div>
            <div className="desc">
              디바이스를 식별 및 판별하고, Risk Scoring을 제공합니다.
            </div>
            <div className="text">
              <div>
                <FontAwesomeIcon icon={faDatabase}
                  style={{color:'#adb5bd'}} /> 자산관리
              </div>
            </div>
          </div>
          <div>
            <div className="desc">
              TTPs 관점으로 체계를 수립하여 위협을 헌팅합니다.
            </div>
            <div className="text">
              <FontAwesomeIcon icon={faSearch}
                style={{color:'#adb5bd'}} /> Hunting
            </div>
          </div>
          <div>
            <div className="desc">
              침해사고에서 구체적인 공격의 증적 자료를 제공합니다.
            </div>
            <div className="text">
              <FontAwesomeIcon icon={faAnalytics}
                style={{color:'#adb5bd'}} /> XSec
            </div>
          </div>
          <div>
            <div className="desc">
              발생한 위협 행위를 다양한 경로로 알려줍니다.
            </div>
            <div className="text">
              <FontAwesomeIcon icon={faFileChartLine}
                style={{color:'#adb5bd'}} /> Response
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};


const CoreTech = () => {
  const coreTech = [
    {
      title: `고성능 패킷\n스트림 저장 시스템`,
      text:'초고속 대규모 네트워크 환경에서 손실 없이 저장하려면 분산 구조로 저장하는 시스템이 필요합니다.',
      model:'(10-2080477)',
      img : '/images/network_blackbox/core_1.png'
    },
    {
      title: '고속 패킷\n검색 방법',
      text:'검색 조건에 특화된 DB를 생성하여 사용자 정의 패턴을 고속으로 검색합니다.',
      model:'(KR2019-008860)',
      img : '/images/network_blackbox/core_2.png'
    },
    {
      title: '패턴 기반\n색인 처리 시스템',
      text:'패킷 재조합 후에 패턴 분석을 하여 판별한 후 해당 애플리케이션에 맞게 실시간으로 색인 처리를 합니다.',
      model:'(10-2080478)',
      img : '/images/network_blackbox/core_3.png'
    },
    {
      title: '시나리오 중심\n실시간 공격 감지 시스템',
      text:'지도학습 기반의 위협 헌팅 모델로 고도화된 위협과 이상 징후를 찾아 실시간으로 사냥합니다.',
      model:'(10-2080479)',
      img : '/images/network_blackbox/core_4.png'
    },
  ]
  return (
    <section className="nbb_4-coretech">
      <h2>
        Core Technology
      </h2>
      <p>
        해킹 침해 사건, 사고의 증적 분석과 대응
      </p>
      <div className="container">
        {coreTech.map((item, idx)=>(
          <div className='core-item' key={item.title}>
            <div>
              <span>{(String(idx +1)).padStart(2,'0')}</span>
              <h3>{item.title}</h3>
              <p className='text'>{item.text}</p>
              <p className='model'>{item.model}</p>
            </div>
            <img src={item.img} alt="coreImg"/>
          </div>
        )
        )}
      </div>
    </section>
  )
};

const Title = () => {
  return (
    <div className="nbb4-title">
      <h1>
        <span>
          Network Blackbox&nbsp;
        </span>
        <span style={{ color: '#adb5bd' }}>
          4.0
        </span>
        <span className="badge">
          New
        </span>
      </h1>
      <h4>
        PREFARE FOR <strong>CYBER WAR</strong>
      </h4>
    </div>
  )
}


const Screenshots = () => {
  const [modalOn, setModalOn] = useState(false);
  const [img, setImg] = useState();
  function imgClick(e){
    setImg(e.currentTarget.attributes.src.nodeValue)
    setModalOn(!modalOn);
  }
  function closeModal(){
    setModalOn(!modalOn);
  }
  // console.log(img)
  return (
    <section className="bb-screenshots2">
      <div className="container">
        <div>
          <div><img onClick={imgClick} src="/images/screenshot/sign_in.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/daily_learn_graph.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/asset_management.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/earth.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/ip_profile.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/scenario.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/meta_search.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/th_rule.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/content.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/util.jpg" alt="" /></div>
          <div><img onClick={imgClick} src="/images/screenshot/detail_view.jpg" alt="" /></div>
        </div>
      </div>
      {modalOn && 
        <div className='modal-container' onClick={closeModal}>
          <div className='modal'>
            <img src={img} alt="" />
            <span className='close-btn' onClick={closeModal}>
            <FontAwesomeIcon icon={faCircleXmark} />
            </span>
          </div>
        </div>
      }
    </section>
  )
}

const Video = () => {
  return (
    <section className="index-teaser" style={{marginBottom: '140px'}}>
      <div className="container" style={{padding:'40px 0 0'}}>
        <div className="video-container">
          <iframe title="index-teaser" width="853" height="480" src="https://www.youtube.com/embed/Mys3Vm4OejY?cc_load_policy=1" frameBorder="0" allowFullScreen={true}></iframe>
        </div>
      </div>
    </section>
  )
}

const GsCertificate = () => {
  function range(min, max) {
    return Math.round(Math.random() * (max - min) + min);
  }
  function makeArr() {
    let arr = [];
    for(var i=0; i<50; i++) {
      arr.push({
        left: `${range(1, 1600)}px`,
        width: `${range(1, 10)}px`,
        height: `${range(1, 10)}px`,
        animationDelay: `${Math.random()*2}s`,
        backgroundColor: `rgb(${range(1,255)}, ${range(1,255)}, ${range(1,255)})`
      });
    }
    return arr;
  }
  return (
    <section className="bb-gs">
      <div className="confetti__container">
        {makeArr().map((d, i)=>(
          <div key={i} className={`confetti confetti--${
            ['fast', 'medium', 'slow'][range(0, 2)]
          }`} style={d} />
        ))}
      </div>
      <div className="container">
        <h4>
          <img src="/images/network_blackbox/gs-cert.png"
            alt="GS Certificate mark"
            style={{
              height:'45px',
              verticalAlign:'middle'
            }} />&nbsp;
          GS인증 1등급 획득
        </h4>
        <p>
          사이버 보안 위협에 효과적으로 대응할 수 있는 Network Blackbox가<br/>
          한국정보통신기술협회(TTA)로부터 국가 소프트웨어 품질 인증인 <em>GS인증 1등급</em>을 획득하였습니다.
        </p>
        <p className="text-center">
          Network Blackbox 3.0<br/>
          인증번호
          &nbsp;21-0562
        </p>
      </div>
    </section>
  )
};

const Patent = () => {
  return(
    <section className="nbb4-patent">
      <div className="container">
        <h2> 특허 등록 내역 </h2>
        <dl>
          <dd>
            고성능 패킷 스트림 저장 시스템 및 이를 이용한 고성능 패킷 스트림 저장 방법
            &nbsp;<small>(10–2080477)</small>
          </dd>
        </dl>
        <dl>
          <dd>
            패턴 기반 색인 처리 시스템 및 이를 이용한 패턴 기반 색인 처리 방법
            &nbsp;<small>(10–2080478)</small>
          </dd>
        </dl>
        <dl>
          <dd>
            시나리오 중심 실시간 공격 감지 시스템 및 이를 이용한 시나리오 중심 실시간 공격 감지 방법
            &nbsp;<small>(10–2080479)</small>
          </dd>
        </dl>
      </div>
      <div className="container">
        <h2> 국제 특허 출원 내역 </h2>
        <dl>
          <dd>
            네트워크 포렌식 시스템 및 이를 이용한 네트워크 포렌식 방법
            &nbsp;<small>(PCT-KR2019-008860)</small>
          </dd>
        </dl>
      </div>
      {/*<div className="container">
        <h2> 조달 등록 내역 </h2>
        <dl>
          <dd>
            <a rel="noreferrer noopener" href="https://digitalmall.g2b.go.kr:8058/dm/pp/goods/dmPpCsGoodsDtlInfo.do?cntrctGoodsMngNo=002161674001&pageTy=null&ctgrDivCd=CS&cntrctGoodsLclId=44&cntrctGoodsMclId=4405" target="_blank">
            통신소프트웨어, 쿼드마이너, 넷블랙 2.0, 네트워크분석솔루션
            </a>
          </dd>
        </dl>
      </div>*/}
    </section>
  )
}

export function Contactus() {
  return(
    <section className="nbb4-contactus">
      <div className="container">
        <ul>
          <li>
            <h4>견적 및 기술문의</h4>
            <br/><br/>
            <Link to="/ko/contact_us.html">
              <FontAwesomeIcon
                icon={faLongArrowRight} /> 페이지 이동
            </Link>
          </li>
        </ul>
      </div>
    </section>
  )
}

export function Packet() {
   return(
    <section className="nbb4-packet-capture">
      <div className="container">
        <h2> 네트워크 패킷 실시간 재조합 </h2>
        <p>
          단순하게 패킷 저장만 하는 것이 아니라,<span className="break-line-auto" />
          <em>Application 계층(L7)까지 재조합</em>하여 네트워크에서 벌어지는 행위를 가시화합니다.
        </p>
      </div>
      <div className="stream">
        <img src="/images/network_blackbox/stream.svg" alt="Packet Stream" />
      </div>
      <div className="stream-container">
        <p>
          ※&nbsp;
          이 외에도 바이러스 검사, 이상 행위 탐지 등의 다양한 후처리를 하고 있습니다.
        </p>
      </div>
    </section>
  )
}

export function Detection() {
  return(
    <section className="nbb4-detection">
      <div className="container">
        <h2> 실시간 지능형 위협 탐지 </h2>
        <p>
          최신 위협탐지 룰을 통해 실시간으로 이상 징후를 탐지합니다.
        </p>
        <ul>
          <li>
            <div>
              <h4>25000+</h4>
              Cyber Threat Hunting
            </div>
          </li>
          <li>
            <div>
              <h4>Realtime</h4>
              Anomaly Detection
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
};

export function ThreatResponse() {
  const containerRef = useRef();

  useEffect(()=>{
    // 가장 높이가 높은 DIV를 찾자
    let maxHeight = 0;
    $(containerRef).find('li>div').each(function(){
      let h = $(this).height();
      maxHeight = (maxHeight < h) ? h: maxHeight;
    });
    // 높이를 다 똑같게 만들자
    $(containerRef).find('li>div').each(function(){
      $(this).css('min-height', maxHeight);
    });
  });

  return(
    <section className="nbb4-threat-response">
      <div className="container" ref={containerRef}>
        <h2>
          <FontAwesomeIcon icon={faExclamationCircle}
            style={{color:'#f03e3e'}} /> 고도화된 위협 대응
        </h2>
        <ul>
          <li>
            <div> 시그니처 기반 공격 탐지 </div>
          </li>
          <li>
            <div> 행위 기반 공격 탐지 </div>
          </li>
          <li>
            <div> C&C, 악성코드, 웜 탐지 </div>
          </li>
          <li>
            <div> 시나리오 기반 공격 탐지 </div>
          </li>
        </ul>
        <p> 탐지된 내역을 다각도로 분석하여 악성 여부에 대한 명확한 가시성을 제공합니다. </p>
      </div>
    </section>
  )
}

export function Analysis() {
  return(
    <section className="nbb4-analysis">
      <div className="container">
        <h2> 심층적인 네트워크 트래픽 분석 </h2>
        <p>
          고도로 정제된 데이터베이스를 통해 사이버 위협을 분석하고, 단순 로그가 아닌 실제 패킷까지 확인할 수 있습니다.
        </p>

        <div className="tables">
          <div className="left">
            <div className="table-database">
              <h5> 20종류 이상의 분석 데이터셋 </h5>
              <table>
              <tbody>
              <tr>
                <td><FontAwesomeIcon icon={faDatabase} className="fa-3x" /></td>
                <td><FontAwesomeIcon icon={faDatabase} className="fa-3x" /></td>
                <td><FontAwesomeIcon icon={faDatabase} className="fa-3x" /></td>
                <td><FontAwesomeIcon icon={faDatabase} className="fa-3x" /></td>
                <td style={{fontSize:'10px'}}>
                  <FontAwesomeIcon icon={faDatabase} className="fa-3x" />
                </td>
                <td style={{fontSize:'10px'}}>
                  <FontAwesomeIcon icon={faDatabase} className="fa-3x" />
                </td>
              </tr>
              <tr>
                <td>Payload</td>
                <td>Flow</td>
                <td>Contents</td>
                <td>Rules</td>
                <td>...</td>
                <td>...</td>
              </tr>
              </tbody>
              </table>
              <h4> 80종류 이상의 조건으로 상관 분석 </h4>
            </div>
            <div className="table-database">
              <table>
              <tbody>
              <tr>
                <td><FontAwesomeIcon icon={faDatabase} className="fa-3x" /></td>
                <td><FontAwesomeIcon icon={faDatabase} className="fa-3x" /></td>
              </tr>
              <tr>
                <td>Stats</td>
                <td>Event</td>
              </tr>
              </tbody>
              </table>
              <h4>
                임계치 기반 분석 및<span className="break-line-auto" />
                다수의 장비에서 수집한 이벤트 분석
              </h4>
            </div>
          </div>
          <div className="right">
            <img src="/images/network_blackbox/scenario.svg" alt="Scenario" />
          </div>
        </div>
      </div>
    </section>
  )
}

export function Ai() {
  return (
    <section className="bb-ai">
      <div className="container">
        <h2> 지도학습 기반의 인공지능 분석 엔진 </h2>
        <p>
          탐지된 이벤트 뿐만 아니라, 네트워크 상에서 벌어지는 다양한 행위를 학습하여 가장 정확하게 탐지합니다. 인공지능이 도출한 결과의 이유와 원인에 대한 설명이 가능한 지도학습 기반의 인공지능 솔루션입니다.
        </p>
      </div>
      <div className="container-picture">
        <ul className="circles">
          <li>
            <div>Flow</div>
          </li>
          <li>
            <div>Statistics</div>
          </li>
          <li>
            <div>Content</div>
          </li>
          <li>
            <div>Event</div>
          </li>
        </ul>
        <div className="head">
          <img src="/images/cloud_blackbox/head-side-brain.svg" alt="Head" />
          <div>
            NBA<br/>
            Algorithm
          </div>
        </div>
        <div className="threat">
          <div>
            고도화된<br/>위협 대응
          </div>
        </div>
      </div>
    </section>
  )
}


export default NetworkBlackbox;