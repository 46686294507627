const CompanyInfoKorea = {
  address: `서울특별시 강남구 테헤란로 138 (역삼동 736) 성홍타워 6층 (06236)`,
  tel: `+82-2-548-1124`,
  fax: `0507-803-5559`,
};

const CompanyInfoEnglish = {
  address: `Quad Miners 6Floor Sunghong Tower, #138 Teheran-ro, Gangnam-gu, Seoul, South Korea (06236)`,
  tel: `+82-2-548-1124`,
  fax: `0507-803-5559`,
};

export {CompanyInfoKorea, CompanyInfoEnglish};