import Wrapper from 'common/Wrapper';
import 'styles/ContactUs.scss';
import { PartnerList, StrategicPartnerList } from 'common/Partners';
import 'styles/Partners2.scss';

const Partners = () => {
  function notLink(link){
    let urlLink = link.currentTarget.href;
    urlLink.includes('nothing!') && link.preventDefault();
  }
  return (
    <Wrapper active="Partners" lang="ko">
      <div className="Partners2">
        <div className="partners-wrapper2">
          <Carousel />
          <Movie />
        </div>
        <List notLink={notLink} />
        <StrategicPartners notLink={notLink} />
        <div className="partners-footer2" />
      </div>
    </Wrapper>
  )
};

const Carousel = () => {
  return (
      <section className="about-photos2">
        <div className="container">
          <div>
            <div><img src="/images/partner-mood/1.jpg" alt="" /></div>
            <div><img src="/images/partner-mood/2.jpg" alt="" /></div>
            <div><img src="/images/partner-mood/3.jpg" alt="" /></div>
            <div><img src="/images/partner-mood/4.jpg" alt="" /></div>
            <div><img src="/images/partner-mood/5.jpg" alt="" /></div>
            <div><img src="/images/partner-mood/6.jpg" alt="" /></div>
            <div><img src="/images/partner-mood/7.jpg" alt="" /></div>
            <div><img src="/images/partner-mood/8.jpg" alt="" /></div>
            <div><img src="/images/partner-mood/9.jpg" alt="" /></div>
            <div><img src="/images/partner-mood/10.jpg" alt="" /></div>
            <div><img src="/images/partner-mood/11.jpg" alt="" /></div>
            <div><img src="/images/partner-mood/12.jpg" alt="" /></div>
          </div>
        </div>
      </section>
  )
}

const Movie = () => {
  return (
    <section className="partners-youtube">
      <div className="container">
        <div className="video-container">
          <iframe title="partners-youtube" width="853" height="480" src="https://www.youtube.com/embed?listType=playlist&list=PLOdghnxHc6BmUdNZ1g_8PwtFXaCaCony3&cc_load_policy=1" frameBorder="0" allowFullScreen={true}></iframe>
        </div>
      </div>
    </section>
  )
}

const List = ({notLink}) => {
  return(
    <section className="partners-list2">
      <div className={`container more`}>
        <h4>
          Quad Miners는 국내외 유수의 기업과 협력 관계를 이어가고 있습니다.
        </h4>
        <ul>
          {PartnerList.map((d, i)=>(<li key={i}>
            <a rel="noreferrer noopener" href={d.href || "nothing!"}
              target={d.href ? "_blank" : "_self"} onClick={notLink}>
              <img src={d.src} style={{height: d.height}} alt={d.alt} />
            </a>
          </li>))}
        </ul>
      </div>
    </section>
  )
}

const StrategicPartners = ({notLink}) => {
  return(
    <section className="partners-list2 partnerships" style={{marginBottom:'100px'}}>
      <div className={`container more`}
        style={{textAlign: 'center'}}>
        <h4>
          Strategic Partnerships with Quad Miners
        </h4>
        <ul>
          {StrategicPartnerList.map((d, i)=>(<li key={i}>
            <a rel="noreferrer noopener" href={d.href || 'nothing!'}
              target={d.href ? "_blank" : "_self"} onClick={notLink}>
              <img src={d.src} style={{height: d.height}} alt={d.alt} />
            </a>
          </li>))}
        </ul>
      </div>
    </section>
  )
}

export default Partners;