import { useEffect } from "react";
import Head from './Head';
import Foot from './Foot';
import LanguageModal from './LanguageModal';

const Wrapper = ({active, lang, children}) => {

  useEffect(() => {
    if(lang === 'ko') {
      document.body.style.background = '#000';
    }
  }, [])

  return (
    <div>
      <Head active={active} lang={lang} />
      {children}
      <Foot active={active} lang={lang} />
      <LanguageModal />
    </div>
  )
};

export default Wrapper;