import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "common/ScrollToTop";
import Home from "en/Home";
import NetworkBlackbox from "en/NetworkBlackbox";
// import FpNids from 'en/FpNids';
import InsiderThreat from "en/InsiderThreat";
import CloudBlackbox from "en/CloudBlackbox";
import QuadX from "en/QuadX";
import Partners from "en/Partners";
import CaseStudy from "en/CaseStudy";
import ContactUs from "en/ContactUs";
import About from "en/About";
import Career from "en/Career";
import HelpDesk from "en/HelpDesk";

const BodyEn = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {["/", "index.html"].map((d) => (
          <Route
            path={d}
            key={d}
            element={<Navigate to={`/en/index.html`} />}
          />
        ))}

        <Route path="/en/index.html" element={<Home />} />
        <Route path="/en/network_blackbox.html" element={<NetworkBlackbox />} />
        {/* <Route path="/en/fp_nids.html" element={<FpNids />} /> */}
        <Route path="/en/insider_threat.html" element={<InsiderThreat />} />
        <Route path="/en/cloud_blackbox.html" element={<CloudBlackbox />} />
        <Route path="/en/quadx.html" element={<QuadX />} />

        <Route path="/en/partners.html" element={<Partners />} />
        <Route path="/en/case_studies.html" element={<CaseStudy />} />
        <Route path="/en/contact_us.html" element={<ContactUs />} />

        <Route path="/en/about.html" element={<About />} />
        <Route path="/en/career.html" element={<Career />} />

        <Route path="/en/help_desk.html" element={<HelpDesk />} />
      </Routes>
    </Router>
  );
};

export default BodyEn;
