import Curve from 'common/Curve';
import Wrapper from 'common/Wrapper';
import { CompanyInfoEnglish as CompanyInfo } from 'common/CompanyInfo';
import 'styles/About.scss';
import { useEffect } from 'react';
import $ from "jquery";
import { CLevelList, TeamList, InvestorList } from 'common/Team';

const About = () => {
  return (
    <Wrapper active="About" lang="en">
      <Intro />
      <Curve />
      <Slogan />
      <Photos />
      <Outline />
      <Work />
      <Ci />
      <Investor />
      <Address />
      <Maps />
    </Wrapper>
  )
};

const Intro = () => {
  return(
  <section className="about-intro">
    <div className="background">
      <div id="stars"/><div id="stars2"/><div id="stars3"/>
    </div>
    <div className="container">
      <h2>
        <strong>The Future</strong> of<br/>
        Software<br/>
        Development
      </h2>
    </div>
  </section>
  )
}

const Slogan = () => {
  return(
    <section className="about-slogan">
      <div className="container">
        <h2>Always thinking of <em>new ideas and concerns.</em></h2>
      </div>
    </section>
  )
}

const Photos = () => {
  return (
    <section className="about-photos2">
        <div className="container">
          <div>
            <div><img src="/images/about/1.jpg" alt="" /></div>
            <div><img src="/images/about/2.jpg" alt="" /></div>
            <div><img src="/images/about/3.jpg" alt="" /></div>
            <div><img src="/images/about/4.jpg" alt="" /></div>
            <div><img src="/images/about/5.jpg" alt="" /></div>
            <div><img src="/images/about/6.jpg" alt="" /></div>
            <div><img src="/images/about/7.jpg" alt="" /></div>
            <div><img src="/images/about/8.jpg" alt="" /></div>
            <div><img src="/images/about/10.jpg" alt="" /></div>
            <div><img src="/images/about/11.jpg" alt="" /></div>
            <div><img src="/images/about/12.jpg" alt="" /></div>
            <div><img src="/images/about/13.jpg" alt="" /></div>
          </div>
        </div>
      </section>
  )
}

const Outline = () => {
  return(
    <section className="about-outline">
      <div className="container">
        <p>
          <em>Quad Miners</em>will solve security threats such as internal leakage and systematically respond to the network security market. Quad Miners will be known as the next generation security solution that will analyze abnormal behaviors in the network, and give real time traffic analysis auditing reports to the network environment
        </p>
      </div>
    </section>
  )
}

const Work = () => {
  return(
    <section className="about-work">
      <div className="container">
        <dl>
          <dt>Vision</dt>
          <dd>
            Quad Miners' provide easy and accurate <em>Cyber Defense Solution</em> to the world.
          </dd>
        </dl>
        <dl>
          <dt>Philosophy</dt>
          <dd>
            We believe that we can grow through <em>practical wisdom.</em><br/>
            Based on our belief, we create <em>a technical oriented ecosystem.</em>
          </dd>
        </dl>
        <dl>
          <dt>Business Introduction</dt>
          <dd>
          Cyber Security market is growing because of the ESM and SIEM market has reached its peak. ESM and SIEM can collect logs and do a part of the analysis but not the way Network Security can store and analyze. Quad Miners has developed a solution where it meets the needs and criteria in order to store and anaylze all network packets in real time.
          </dd>
        </dl>
      </div>
    </section>
  );
}

const Ci = () => {
  return(
    <section className="about-ci">
      <div className="container">
        <div>
          <img src="/images/common/logo_rect_3a3a3c.png" alt="Quad Miners logo" />
        </div>
        <div style={{textAlign: 'center'}}>
          <div className="empx">
            CYBER DEFENSE
          </div>
        </div>
        <h4>
          Quad Miners' CI is reflected by the code writers of <em>four business domains.</em><span className="break-line-auto" />
          A shovel-shaped pictogram symbolizes <em>deep digging, representing patience and persistence.</em>
        </h4>
      </div>
    </section>
  )
}

const Investor = () => {
  return(
    <section className="partners-list">
      <div className={`container more`} style={{ textAlign: 'center' }}>
        <h4>Investors</h4>
        <ul>
          {InvestorList.map((d, i)=>(<li key={i}>
            <a href={d.href} target={"_blank"} rel="noreferrer noopener">
              <img src={`/images/investor/${d.src}`}
                style={{ height: d.height }} alt={d.name} />
            </a>
          </li>))}
        </ul>
      </div>
    </section>
  );
}

const Address = () => {
  return(
    <section className="about-address">
      <div className="container">
        <dl>
          <dt>Company</dt>
          <dd>Quad Miners</dd>
        </dl>
        <dl>
          <dt>Founded</dt>
          <dd>November 24, 2017</dd>
        </dl>
        <dl>
          <dt>CRN</dt>
          <dd>608-88-00869</dd>
        </dl>
        <dl>
          <dt>Industry</dt>
          <dd>
            Service<br/>System Software Development and Supply<br/>
            <small>(Hardware, Software Sales Consulting)</small>
          </dd>
        </dl>
        <dl>
          <dt>Address</dt>
          <dd>{CompanyInfo.address}</dd>
        </dl>
        <dl>
          <dt>Phone Number</dt>
          <dd>{CompanyInfo.tel}</dd>
        </dl>
        <dl>
          <dt>FAX</dt>
          <dd>{CompanyInfo.fax}</dd>
        </dl>
      </div>
    </section>
  )
}

const Maps = () => {
  useEffect(()=>{
    $(()=>{
      // 37.504596, 127.025783 (신논현)
      // 37.516360, 127.037850 (강남구청)
      let centreCarte = new window.google.maps.LatLng(37.4996357,127.034346);
      let map = new window.google.maps.Map(document.getElementById('Map'), {
        center: centreCarte,
        zoom: 17,
        zoomControlOptions: {
          style: window.google.maps.ZoomControlStyle.SMALL,
          position: window.google.maps.ControlPosition.RIGHT_BOTTOM
        },
        panControl: false,
        scrollwheel: false,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false
      });
      new window.google.maps.Marker({
        position: centreCarte,
        map: map,
        title: 'Quad Miners',
      });
    });
  }, []);

  return (
    <section className="about-map">
      <div id="Map" />
      <div className="help-text">
        Parking is difficult because there is no parking space. I ask for your understanding.
      </div>
    </section>
  )
}

export default About;