import "../styles/NoticeModal.scss";

export function NoticeModal({ setShowNoticeModal }) {
	return (
		<div className="notice-modal">
			<div class="modal" id="notice202411">
				<div class="modal-inner">
					<div class="modal-box modal-box-md">
						<div class="modal-header">
							<h2 class="size-32 sm-size-24 weight-400 lh-125">
								무상증자 결정
							</h2>
							<p class="align-center">
								(공고기간 2024년 11월 7일 ~ 2024년 11월 22일)
							</p>
						</div>
						<div class="modal-body">
							<div class="modal-content">
								<div class="content-items">
									<div class="content-item">
										<p>주식회사 쿼드마이너</p>
										<table>
											<tr>
												<td
													rowspan="2"
													class="align-left"
												>
													1. 신주의 종류와 수
												</td>
												<td class="align-center">
													보통주식 (주)
												</td>
												<td class="align-right">
													3,849,678
												</td>
											</tr>
											<tr>
												<td class="align-center">
													기타주식 (주)
												</td>
												<td class="align-right">
													3,097,773
												</td>
											</tr>
											<tr>
												<td
													class="align-left"
													colspan="2"
												>
													2. 1주당 액면가액 (원)
												</td>
												<td class="align-right">500</td>
											</tr>
											<tr>
												<td
													rowspan="2"
													class="align-left"
												>
													3. 증자전 발행주식총수
												</td>
												<td class="align-center">
													보통주식 (주)
												</td>
												<td class="align-right">
													183,318
												</td>
											</tr>
											<tr>
												<td class="align-center">
													기타주식 (주)
												</td>
												<td class="align-right">
													147,513
												</td>
											</tr>
											<tr>
												<td
													class="align-left"
													colspan="2"
												>
													4. 신주배정기준일
												</td>
												<td class="align-center">
													2024년 11월 22일
												</td>
											</tr>
											<tr>
												<td
													rowspan="2"
													class="align-left"
												>
													5. 1주당 신주배정 주식수
												</td>
												<td class="align-center">
													보통주식 (주)
												</td>
												<td class="align-right">21</td>
											</tr>
											<tr>
												<td class="align-center">
													기타주식 (주)
												</td>
												<td class="align-right">21</td>
											</tr>
											<tr>
												<td
													class="align-left"
													colspan="2"
												>
													6. 신주의 배당기산일
												</td>
												<td class="align-center">
													2024년 01월 01일
												</td>
											</tr>
											<tr>
												<td
													class="align-left"
													colspan="2"
												>
													7. 신주권교부예정일
												</td>
												<td class="align-center">-</td>
											</tr>
											<tr>
												<td
													class="align-left"
													colspan="2"
												>
													8. 신주의 상장 예정일
												</td>
												<td class="align-center">-</td>
											</tr>
											<tr>
												<td
													class="align-left"
													colspan="2"
												>
													9. 이사회결의일(결정일)
												</td>
												<td class="align-center">
													2024년 11월 07일
												</td>
											</tr>
											<tr>
												<td
													rowspan="2"
													class="align-left"
												>
													- 사외이사 참석여부
												</td>
												<td class="align-center">
													참석(명)
												</td>
												<td class="align-right">2</td>
											</tr>
											<tr>
												<td class="align-center">
													불참(명)
												</td>
												<td class="align-right">-</td>
											</tr>
											<tr>
												<td
													class="align-left"
													colspan="2"
												>
													- 감사(감사위원)참석 여부
												</td>
												<td class="align-center">
													참석
												</td>
											</tr>
										</table>
									</div>
									<div class="content-item">
										<p>10. 기타 투자판단에 참고할 사항</p>
										<table>
											<tr>
												<td class="align-center">
													구분
												</td>
												<td class="align-center">
													무상증자 전
												</td>
												<td class="align-center">
													무상증자 배정내역
												</td>
												<td class="align-center">
													무상증자 후
												</td>
											</tr>
											<tr>
												<td class="align-center">
													보통주식
												</td>
												<td class="align-right">
													183,318
												</td>
												<td class="align-right">
													3,849,678
												</td>
												<td class="align-right">
													4,032,996
												</td>
											</tr>
											<tr>
												<td class="align-center">
													기타주식
												</td>
												<td class="align-right">
													147,513
												</td>
												<td class="align-right">
													3,097,773
												</td>
												<td class="align-right">
													3,245,286
												</td>
											</tr>
											<tr>
												<td class="align-center">
													합계
												</td>
												<td class="align-right">
													330,831
												</td>
												<td class="align-right">
													6,947,451
												</td>
												<td class="align-right">
													7,278,282
												</td>
											</tr>
										</table>
									</div>
									<div class="content-item">
										<p>※ 기타주식에 관한 사항</p>
										<table>
											<tr>
												<td class="align-left">
													정관의 근거
												</td>
												<td class="align-left">
													제11조의5
												</td>
											</tr>
											<tr>
												<td class="align-left">
													주식의 내용
												</td>
												<td class="align-left">
													상환전환우선주
												</td>
											</tr>
											<tr>
												<td class="align-left">기타</td>
												<td class="align-left"></td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>

						<a
							href="#"
							class="btn-close "
							onClick={(e) => {
								e.preventDefault();
								setShowNoticeModal(false);
							}}
						>
							&times;
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
