import Wrapper from "common/Wrapper";
import "styles/ContactUs2.scss";

const ContactUs = () => {
  return (
    <Wrapper active="ContactUs" lang="ko">
      <section className="ContactUs2">


        <div className="meet_our_team">
          <h4>
            전문가와 상의하세요.
          </h4>
          <p>
            쿼드마이너 영업 팀에게 문의하여 <strong>요구사항</strong>을 상세히 알아보세요.<br/>
            <strong>제품 정보</strong>를 얻고, <strong>가격 정보</strong>를 확인하고, <strong>데모</strong>를 요청하고, <strong>전문적인 상담</strong>을 통해 필요한 도움을 받을 수 있습니다.
          </p>
          <div className="wrapper">
            {[1,2,3,4].map(d=>(
              <div key={d}>
                <img src={`/images/contactus/sales_${d}.jpg`} alt="" />
              </div>
            ))}
          </div>
        </div>

        <div className="button_layer">

            <div className="card">
              <a
                rel="noreferrer noopener"
                href="https://sales.quadminers.com/hc/ko/requests/new"
                target="_blank"
              >
                <div className="box-content">
                  <h4>영업 팀 문의</h4>
                  <p>
                    영업 및 제품 소개
                  </p>
                </div>
                <div className="btn-box">
                  세일즈 문의
                </div>
                <div className="clearfix" />
              </a>
            </div>

        </div>

      </section>
    </Wrapper>
  );
};

export default ContactUs;
