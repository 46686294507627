import Wrapper from 'common/Wrapper';
import 'styles/CloudBlackbox.scss';
import { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDatabase, faSearch, faAnalytics, faFileChartLine,
  faSackDollar, faTachometerAlt, faExclamationTriangle
} from "@fortawesome/pro-light-svg-icons";
import $ from "jquery";
import {
  Packet, Detection, ThreatResponse, Analysis, Ai, Contactus
} from "ko/NetworkBlackbox";

const CloudBlackbox = () => {
  return (
    <Wrapper active="CloudBlackbox" lang="ko">
      <div style={{backgroundColor: '#fff'}}>
        <Intro />
        <Slogan />
        <Chain />
        <Structure />
        <Collect />
        <Accounting />
        <Packet />
        <Detection />
        <ThreatResponse />
        <Analysis />
        <Instance />
        <Ai />
        <Contactus />
      </div>
    </Wrapper>
  )
};

const Intro = () => {
  const [isMount, setIsMount] = useState(false);
  useEffect(()=>{
    setIsMount(true)

    let c = document.getElementById('canvas').getContext('2d')
    let canvas = c.canvas
    let vertices = []

    // Effect Properties
    let vertexCount = 7000;
    let vertexSize = 1;
    let oceanWidth = 204;
    let oceanHeight = -80;
    let gridSize = 32;
    let waveSize = 16;
    let perspective = 100;

    // Common variables
    let depth = (vertexCount / oceanWidth * gridSize)
    let frame = 0;
    let { sin, cos, PI } = Math

    let loop = () => {
      let rad = sin(frame / 100) * PI / 20
      let rad2 = sin(frame / 50) * PI / 10
      frame++;

      c.fillStyle = `hsla(0, 100%, 0%, 0.1)`;
      c.fillRect(0, 0, canvas.width, canvas.height)
      c.save();
      c.translate(canvas.width / 2, canvas.height / 2);

      c.beginPath()
      vertices.forEach((vertex, i) => {
        // let ni = i + oceanWidth;
        let x = vertex[0] - frame % (gridSize * 2);
        let z = vertex[2] - frame * 2 % gridSize + (i % 2 === 0 ? gridSize / 2 : 0)
        let wave = (cos(frame / 45 + x / 50) - sin(frame / 20 + z / 50) + sin(frame / 30 + z*x / 10000));
        let y = vertex[1] + wave * waveSize;
        let a = Math.max(0, 1 - (Math.sqrt(x ** 2 + z ** 2)) / depth);
        let tx, ty, tz;
        y -= oceanHeight;
        tx = x; ty = y; tz = z;

        // Rotation Y
        tx = x * cos(rad) + z * sin(rad);
        tz = -x * sin(rad) + z * cos(rad);
        x = tx; y = ty; z = tz;

        // Rotation Z
        tx = x * cos(rad) - y * sin(rad);
        ty = x * sin(rad) + y * cos(rad);
        x = tx; y = ty; z = tz;

        // Rotation X
        ty = y * cos(rad2) - z * sin(rad2);
        tz = y * sin(rad2) + z * cos(rad2);
        x = tx; y = ty; z = tz;

        x /= z / perspective;
        y /= z / perspective;

        if (a < 0.01) return;
        if (z < 0) return;

        c.globalAlpha = a
        c.fillStyle = `hsl(${200 + wave * 20}deg, 100%, 50%)`
        c.fillRect(
          x - a * vertexSize / 2,
          y - a * vertexSize / 2,
          a * vertexSize,
          a * vertexSize
        );
        c.globalAlpha = 1;
      })
      c.restore();
      isMount && requestAnimationFrame(loop);
    };

    for (let i = 0; i < vertexCount; i++) {
      let x = i % oceanWidth
      let y = 0
      let z = i / oceanWidth >> 0
      let offset = oceanWidth / 2
      vertices.push([(-offset + x) * gridSize, y * gridSize, z * gridSize])
    }
    loop();

    return () => {
      setIsMount(false)
    };
  },[isMount]);

  return (
    <section className="cb-intro">
      <div className="background">
        <canvas id="canvas" />
      </div>
      <div className="container">
        <h2>
          <img src="/images/common/cb_center_white.png"
            alt="Cloud Blackbox Logo" />
          <br/>
          Network Traffic Analysis for Cloud
        </h2>
      </div>
    </section>
  )
}

const Slogan = () => {
  return (
    <section className="cb-slogan">
    <div className="container">
      <h2>
        가장 진보적인 물리-가상화 네트워크 보안 솔루션
      </h2>
    </div>
  </section>
  );
}

const Chain = () => {
  const containerRef = useRef();

  useEffect(()=>{
    $(()=>{
      // 가장 높이가 높은 DIV를 찾자
      let maxHeight = 0;
      $(containerRef.current).find('li>div').each(function(){
        let h = $(this).height();
        maxHeight = (maxHeight < h) ? h: maxHeight;
      });

      // 높이를 다 똑같게 만들자
      $(containerRef.current).find('li>div').each(function(){
        $(this).css('min-height', maxHeight);
      });
    });
  });
  return (
    <section className="cb-chain">
      <div className="container" ref={containerRef}>
        <ul>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon icon={faDatabase}
                  style={{color:'#adb5bd'}} /> Collect
              </h4>
                North-South, East-West는 물론 Private, Public 클라우드 전방향의 네트워크 트래픽을 수집합니다.
            </div>
          </li>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon icon={faSearch}
                  style={{color:'#adb5bd'}} /> Detect
              </h4>
              최신 위협탐지 룰을 통해 실시간으로 고도화된 위협 및 이상 징후를 탐지합니다.
            </div>
          </li>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon icon={faAnalytics}
                  style={{color:'#adb5bd'}} /> Analysis
              </h4>
              고도로 정제된 DB를 통해 사이버 위협을 분석하고, 실제 패킷까지 확인할 수 있습니다.
            </div>
          </li>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon icon={faFileChartLine}
                  style={{color:'#adb5bd'}} />  Response
              </h4>
              이벤트가 감지되면, 신속하게 대응 조치를 취할 수 있도록 다양한 경로로 알려줍니다.
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
}

const Structure = () => {
  return (
    <section className="cb-structure">
      <div className="container">
        <h2>
          변화무쌍한 클라우드 환경에 대응
        </h2>
        <ul>
          <li>
            <div>
              <img src="/images/cloud_blackbox/aws.jpg" alt="aws" />
              <br/>
              Public 클라우드 환경
            </div>
          </li>
          <li>
            <div>
              <img src="/images/cloud_blackbox/k8s_k8s.jpg" alt="k8s" />
              <br/>
              Private 클라우드 환경
            </div>
          </li>
          <li>
            <div>
              <img src="/images/cloud_blackbox/all.jpg" alt="all" />
              <br/>
              Hybrid 클라우드 환경
            </div>
          </li>
        </ul>
        <p>
          ※&nbsp;
          현재 Public 클라우드는 AWS만 지원하고 있습니다. Microsoft Azure, Google Cloud Platform 등의 환경도 지원할 계획입니다.
        </p>
      </div>
    </section>
  );
}

const Collect = () => {
  return (
    <section className="cb-collect">
      <div className="container">
        <h2>
          전방향 네트워크 트래픽 수집
        </h2>
        <ul>
          <li>
            <div>
              <h4>North-South</h4>
            </div>
          </li>
          <li>
            <div>
              <h4>East-West</h4>
            </div>
          </li>
          <li>
            <div>
              <h4>Instance Level</h4>
            </div>
          </li>
        </ul>
        <p>
          특별하게 설계된 네트워크 인터페이스를 통해 물리적인 구간은 물론, 가상화된 구간과 특히 인스턴스 간의 통신까지 전체 트래픽을 수집할 수 있습니다.
        </p>
      </div>
    </section>
  )
}

const Accounting = () => {
  return (
    <section className="cb-accounting">
      <div className="container">
        <h2>
          <FontAwesomeIcon
            icon={faSackDollar}
            style={{color:'#37b24d'}} /> 선택적인 비용 절감
        </h2>
        <ul>
          <li>
            시스템 구성에 맞는 감시 레벨을 선택해서 자원을 줄일 수 있습니다.
          </li>
          <li>
            환경과 상황에 따라 패킷을 선별적으로 저장할 수 있기 때문에 합리적인 비용으로 운영이 가능합니다.
          </li>
        </ul>
      </div>
    </section>
  )
}

const Instance = () => {
  return (
    <section className="cb-instance">
      <div className="container">
        <h2>
          인스턴스 및 서비스 가시화
        </h2>
        <p>
          온프레미스와 클라우드 환경을 통합 분석할 수 있으며, 내부 인스턴스, 서비스, 가상 장비의 네트워크 트래픽을 심층적으로 분석할 수 있습니다.
        </p>
        <ul>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon
                  icon={faTachometerAlt} /> 퍼포먼스 측정
              </h4>
              서비스 간 트래픽 양과 속도를 측정
            </div>
          </li>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon
                  icon={faAnalytics} /> 인스턴스 행위 분석
              </h4>
              각종 행위를 타임라인 기반으로 출력
            </div>
          </li>
          <li>
            <div>
              <h4>
                <FontAwesomeIcon
                  icon={faExclamationTriangle} /> 경고 시스템
              </h4>
              탐지된 이벤트를 다양한 경로를 통해 알림
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default CloudBlackbox;