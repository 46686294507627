import BodyKo from 'ko/Body';
import BodyEn from 'en/Body';
import 'styles/App.scss';

const App = ({ lang }) => {
  if(lang === 'ko') {
    return (<BodyKo />)
  }
  return (<BodyEn />);
};

export default App;