import { Link } from 'react-router-dom';
import Wrapper from 'common/Wrapper';
import Curve from 'common/Curve';
import 'styles/Career.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import { faKey, faCoffee, faObjectGroup } from "@fortawesome/pro-regular-svg-icons";

const Career = () => {
  return (
    <Wrapper active="Career" lang="en">
      <Intro />
      <Curve />
      <Field />
      <Video />
      <Conditions />
      <Photos />
      <Right />
    </Wrapper>
  );
};

const Intro = () => {
  return(
    <section className="career-intro">
      <div className="background" />
      <div className="container">
        <h2>Let's Work together</h2>
      </div>
    </section>
  )
}

const Field = () => {
  return(
    <section className="career-field">
      <div className="container">
        <h2>Job/Career</h2>
        <ul>
          <li className="_deactivate">
            <h4>
              <FontAwesomeIcon icon={faKey} />  Security Technical Engineer
            </h4>
            Information Security, Network Sector<br />
            System Engineer
            <hr/>
            Confiugure and Deploy Server/Storage Infrastructure<br />
            Operate and Manager Linux System<br />
            Skilled typing various Linux Script<br />
          </li>
          <li className="_deactivate">
            <h4>
              <FontAwesomeIcon icon={faCoffee} /> Engine Developer<br /><br />
            </h4>
            C-Language, Python, Linux<br />
            Business Logic Developer
            <hr/>
            API Developer operating Python+Django<br />
            Experienced Linux Based Programmer<br />
            Experienced Big Data Processor
          </li>
          <li className="_deactivate">
            <h4>
              <FontAwesomeIcon icon={faObjectGroup} /> Front-End Web Developer
            </h4>
            JavaScript, React, WebGL<br />
            UI/UX Developer
            <hr/>
            Develop Modern Web apps with React<br />
            Improve Product's UI/UX<br />
            Improve Front-End Web Performance
          </li>
        </ul>
      </div>
    </section>
  )
};

const Video = () => {
  return (
    <section className="index-teaser">
      <div className="container" style={{padding:'40px 0 0'}}>
        <div className="video-container">
          <iframe title="index-teaser" width="853" height="480" src="https://www.youtube.com/embed/veuAerWELoo?cc_load_policy=1" frameBorder="0" allowFullScreen={true}></iframe>
        </div>
      </div>
    </section>
  )
}

const Conditions = () => {
  return(
    <section className="career-conditions">
      <div className="container">
        <h2>Working Enviornment</h2>
        <dl>
          <dt>Developed <br />Enviornment</dt>
          <dd>
            Provide Mac Pro Notebook or User preference.<br />
            Invest fully in Softwares.<br />
            Develop only using the BEST Tools.<br />
          </dd>
        </dl>
        <dl>
          <dt>Benefits</dt>
          <dd>
            Work Hours 9am ~ 6pm<br />
            Vacation Days 15+
          </dd>
        </dl>
      </div>
    </section>
  )
}

const Photos = () => {
  return (
    <section className="bb-screenshots2">
      <div className="container">
        <div>
          <div><img src="/images/mood/2.jpg" alt="Dennis" /></div>
          <div><img src="/images/mood/3.jpg" alt="Yonguk" /></div>
          <div><img src="/images/mood/4.jpg" alt="Ven" /></div>
          <div><img src="/images/mood/5.jpg" alt="EB" /></div>
          <div><img src="/images/mood/6.jpg" alt="Jonny" /></div>
          <div><img src="/images/mood/7.jpg" alt="Yonguk" /></div>
          <div><img src="/images/mood/8.jpg" alt="VR" /></div>
          <div><img src="/images/mood/9.jpg" alt="Gaming" /></div>
        </div>
      </div>
    </section>
  )
}

const Right = () => {
  return(
    <section className="career-right">
      <div className="container">
        <h2>Qualification</h2>
      </div>
      <div className="container">
        <table className="table">
          <thead>
          <tr>
            <th></th>
            <th>Security Technical Engineer</th><th>Engine, Front-End Web Developer</th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <th>Type of Employment</th>
              <td>Full-Time (3 months Trial)</td>
              <td>Full-Time (3 months Trial)</td>
            </tr>
            <tr>
              <th>Recruitment	</th>
              <td>2 years or more experience</td><td>Entry or Senior Level</td>
            </tr>
            <tr>
              <th>Sex</th>
              <td>No Preference</td><td>No Preference</td>
            </tr>
            <tr>
              <th>Age</th>
              <td>No specific preference</td><td>No specific preference</td>
            </tr>
            <tr>
              <th>Education</th>
              <td>No Preference</td><td>No Preference</td>
            </tr>
            <tr>
              <th>Linux Skills</th>
              <td>Intermediate or Higher</td><td>Intermediate or Higher</td>
            </tr>
            <tr>
              <th>Network Skills</th>
              <td>Intermediate or Higher</td><td>Intermediate or Higher</td>
            </tr>
            <tr>
              <th>Python Skills</th>
              <td>-</td><td>Intermediate or Higher</td>
            </tr>
            <tr>
              <th>Certification</th>
              <td>CISA<br/>CISSP<br/>Information Security Articles<br/>Information processing articles</td>
              <td>Information processing articles</td>
            </tr>
            <tr>
              <th>Preference</th>
              <td>Security Solution Experience</td><td>IT Security Career</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="container">
        <h3>Send Resume/cover page and our employee will get back to you.</h3>
        <p>
          No specific form is required but send it as a <em>PDF file.</em><br/>
          Focus on Work area and <em>portfolio.</em>
        </p>
      </div>
    </section>
  )
}

export default Career;