const PartnerList = [
  {href:'https://www.piolink.com', src:'/images/partners/partner_logo_piolink.png',height:'42px', alt:'PIOLINK'},
  {href:'https://www.isd.co.kr/', src:'/images/partners/partner_logo_insung_digital.png',height:'28px', alt:'INSUNG DIGITAL'},
  {href:'https://www.snainfo.com', src:'/images/partners/partner_logo_sna.png',height:'35px', alt:'SNA'},
  // {href:'https://www.rahinfotech.com/', src:'/images/partners/partner_logo_rahinfotech.jpg',height:'24px', alt:'RAH Infotech'},
  {href:'https://www.tech-titan.com', src:'/images/partners/partner_logo_techtitan.png',height:'25px', alt:'Tech Titan'},
  {href:'https://www.skshieldus.com/kor/index.do', src:'/images/partners/partner_logo_skshieldus.png', height:'45px', alt:'SK shieldus'},
  {href:'https://www.hyundaihds.co.kr/homepage/common/index.do', src:'/images/partners/partner_logo_hds.png',height:'33px', alt:'Hyundai hds'},
  {href:'http://speraworks.com/', src:'/images/partners/partner_logo_speraworks.png',height:'40px', alt:'SPERAWORKS'},
  {href:'http://www.solmo.co.kr/', src:'/images/partners/partner_logo_solmo.png',height:'50px', alt:'SOLMO'},
  {href:'http://pro-comsys.com/main/main.html', src:'/images/partners/partner_logo_comsys.png',height:'65px', alt:'COMSYS'},
  {href:'', src:'/images/partners/partner_logo_adn.png',height:'33px', alt:'Application Delivery Network'},
  {href:'http://www.hana-it.com', src:'/images/partners/partner_logo_hanait.png',height:'55px', alt:'HanA IT, Inc'},
  {href:'http://utron.kr', src:'/images/partners/partner_logo_utron.png',height:'65px', alt:'Utron'},
  {href:'https://amonsoft.co.kr', src:'/images/partners/partner_logo_amonsoft.png',height:'45px', alt:'AmonSoft'},
  {href:'http://www.e-scom.co.kr', src:'/images/partners/partner_logo_scom.png',height:'40px', alt:'SCOM'},
  {href:'http://www.aegisk.com/', src:'/images/partners/partner_logo_aegisk.png',height:'40px', alt:'Aegis-K'},


  {href:'https://www.igloo.co.kr/', src:'/images/partners/partner_logo_igloo.png',height:'28px', alt:'IGLOO'},
  {href:'', src:'/images/partners/partner_logo_soletech.png',height:'35px', alt:'SOLETECH'},
  {href:'http://www.cywellsystem.com/cywellSystem.do', src:'/images/partners/partner_logo_cywell.png',height:'45px', alt:'CYWELL System'},
  {href:'', src:'/images/partners/partner_logo_secumoa.png',height:'35px', alt:'SECUMOA'},
  {href:'http://www.itls.co.kr/', src:'/images/partners/partner_logo_itls.png',height:'40px', alt:'TLS'},
  {href:'http://isecu.co.kr/', src:'/images/partners/partner_logo_isecu.png',height:'27px', alt:'ISECU'},
  {href:'https://dongyangcnt.com', src:'/images/partners/partner_logo_dongyang.png',height:'35px', alt:'Dongyang CNT'},
  {href:'http://www.crk.co.kr', src:'/images/partners/partner_logo_crk.png',height:'45px', alt:'CRK Technologies'},
  {href:'', src:'/images/partners/partner_logo_databayit.png',height:'55px', alt:'DATABAYIT'},
  {href:'http://www.likesoft.co.kr/', src:'/images/partners/partner_logo_likesoft.png',height:'25px', alt:'LIKESOFT'},
  {href:'http://www.rootinc.co.kr/', src:'/images/partners/partner_logo_root.png',height:'40px', alt:'ROOT inc'},
  {href:'http://www.wkit.co.kr/', src:'/images/partners/partner_logo_wkit.png',height:'30px', alt:'Wkit'},
  {href:'http://gtesecu.co.kr/', src:'/images/partners/partner_logo_gtesecu.png',height:'70px', alt:'GTESecu'},
  {href:'https://www.monitorapp.com', src:'/images/partners/partner_logo_monitorapp.png',height:'20px', alt:'MONITORAPP'},
  {href:'http://www.gs-ns.co.kr/', src:'/images/partners/partner_logo_gsns.png',height:'55px', alt:'굿서비스앤솔루션'},
  {href:'https://onnara.pro/', src:'/images/partners/partner_logo_onnara.png',height:'80px', alt:'ONNARA'},
  {href:'https://asteriacyber.com/', src:'/images/partners/partner_logo_asteriacyber.png',height:'88px', alt:'Asteria Cyberindo Pratama'},
  {href:'https://www.pinolike.co.kr/', src:'/images/partners/partner_logo_pinolike.png',height:'50px', alt:'PINOLIKE'},
  {href:'https://www.ttsolution.net', src:'/images/partners/partner_logo_ttsolution.png',height:'40px', alt:'TechSolution Indonesia'},
  {href:'https://www.bluezebra.co.th/', src:'/images/partners/partner_logo_bluezebra.png',height:'50px', alt:'BlueZera Network Security'},
];

const StrategicPartnerList = [
  {href:'https://www.gartner.com/en', src:'/images/partners/gartner.png', height:'20px', alt:'Gartner'},
  {href:'https://www.ibm.com', src:'/images/partners/ibm.png', height:'30px', alt:'IBM'},
  {href:'https://www.ahnlab.com', src:'/images/partners/ahnlab.png', height:'26px', alt:'AhnLab'},
  {href:'https://logpresso.com', src:'/images/partners/logpresso.png', height:'22px', alt:'Logpresso'},
];

export {PartnerList, StrategicPartnerList};