import { Link } from 'react-router-dom';
import Wrapper from 'common/Wrapper';
import Curve from 'common/Curve';
import 'styles/Career2.scss';
import { useState, useEffect } from 'react';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import { faKey, faCoffee, faObjectGroup } from "@fortawesome/pro-regular-svg-icons";

const Career = () => {
  return (
    <Wrapper active="Career" lang="ko">
      <div className="Career2">
        <Intro />
        <Field />
        <Video />
        <Conditions />
        <Photos />
        <Right />
      </div>
    </Wrapper>
  );
};

const Intro = () => {
  return(
    <section className="career-intro2">
      <div className="background" />
      <div className="container">
        <h2>당신과 일하고 싶습니다</h2>
      </div>
    </section>
  )
}

const Field = () => {
  return(
    <section className="career-field2">
      <div className="container">
        <h2>채용분야</h2>
        <ul>
          <li className="_deactivate">
            <h4>
              <FontAwesomeIcon icon={faKey} /> 보안 엔지니어
            </h4>
            정보보안, 네트워크 분야<br/>
            시스템 엔지니어
            <hr/>
            서버/스토리지 인프라 설계 및 구축<br/>
            Linux 시스템 운영 및 관리<br/>
            리눅스 각종 스크립트 작성 가능자
          </li>
          <li className="_deactivate">
            <h4>
              <FontAwesomeIcon icon={faCoffee} /> 엔진 개발자
            </h4>
            C언어, Python, 리눅스<br/>
            비즈니스 로직 개발자
            <hr/>
            Python+Django를 사용한 API 개발<br/>
            리눅스 기반 프로그래밍 경험자<br/>
            대용량 데이터베이스 처리 경험자
          </li>
          <li className="_deactivate">
            <h4>
              <FontAwesomeIcon icon={faObjectGroup} /> 프론트엔드 웹 개발자
            </h4>
            JavaScript, React, WebGL<br/>
            UI/UX 개발자
            <hr/>
            React 기반의 프론트엔드 컴포넌트 개발<br/>
            솔루션의 전반적인 UI/UX 향상 업무<br/>
            프론트엔드 전반의 퍼포먼스 향상 업무
          </li>
        </ul>
      </div>
    </section>
  )
};

const Video = () => {
  return (
    <section className="index-teaser">
      <div className="container" style={{padding:'40px 0 0'}}>
        <div className="video-container">
          <iframe title="index-teaser" width="853" height="480" src="https://www.youtube.com/embed/veuAerWELoo?cc_load_policy=1" frameBorder="0" allowFullScreen={true}></iframe>
        </div>
      </div>
    </section>
  )
}

const Conditions = () => {
  return(
    <section className="career-conditions2">
      <div className="container">
        <h2>근무환경</h2>
        <dl>
          <dt>개발환경</dt>
          <dd>
            맥북프로(또는 그에 준하는 장비) 지급.<br/>
            소프트웨어에 돈을 아끼지 않습니다.<br/>
            가장 좋은 도구만 사용하여 개발합니다.
          </dd>
        </dl>
        <dl>
          <dt>복리후생</dt>
          <dd>
            주 5일제 <small>(오전9시 ~ 오후6시)</small><br/>
            자유로운 연차 사용 (15일+)
          </dd>
        </dl>
      </div>
    </section>
  )
}

const Photos = () => {
  return (
    <section className="bb-screenshots2">
      <div className="container">
        <div>
          <div><img src="/images/mood/2.jpg" alt="Dennis" /></div>
          <div><img src="/images/mood/3.jpg" alt="Yonguk" /></div>
          <div><img src="/images/mood/4.jpg" alt="Ven" /></div>
          <div><img src="/images/mood/5.jpg" alt="EB" /></div>
          <div><img src="/images/mood/6.jpg" alt="Jonny" /></div>
          <div><img src="/images/mood/7.jpg" alt="Yonguk" /></div>
          <div><img src="/images/mood/8.jpg" alt="VR" /></div>
          <div><img src="/images/mood/9.jpg" alt="Gaming" /></div>
        </div>
      </div>
    </section>
  )
}

const Right = () => {
  return(
    <section className="career-right2">
      <div className="container">
        <h2>지원 자격</h2>
      </div>
      <div className="container">
        <table className="table">
          <thead>
          <tr>
            <th></th>
            <th>보안 엔지니어</th><th>엔진, 프론트엔드 웹 개발자</th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <th>채용형태</th>
              <td>정규직 (수습 3개월)</td><td>정규직 (수습 3개월)</td>
            </tr>
            <tr>
              <th>모집</th>
              <td>해당 경력 2년 이상</td><td>신입/경력자</td>
            </tr>
            <tr>
              <th>성별</th>
              <td>무관</td><td>무관</td>
            </tr>
            <tr>
              <th>나이</th>
              <td>제한 없음</td><td>제한 없음</td>
            </tr>
            <tr>
              <th>학력</th>
              <td>무관</td><td>무관</td>
            </tr>
            <tr>
              <th>리눅스 지식</th>
              <td>중급 이상</td><td>중급 이상</td>
            </tr>
            <tr>
              <th>네트워크 지식</th>
              <td>중급 이상</td><td>중급 이상</td>
            </tr>
            <tr>
              <th>Python 지식</th>
              <td>-</td><td>중급 이상</td>
            </tr>
            <tr>
              <th>우대 자격증</th>
              <td>CISA<br/>CISSP<br/>정보보안기사<br/>정보처리기사</td><td>정보처리기사</td>
            </tr>
            <tr>
              <th>우대 사항</th>
              <td>보안솔루션 경험자 우대</td><td>IT 보안 관련 경력</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="container">
        <h3>이력서와 자기소개서를 보내주시면 담당자가 검토후 연락 드리겠습니다.</h3>
        <p>
          양식은 자유로우나 문서는 반드시 <em>PDF</em>로 보내주세요.<br/>
          업무 영역과 <em>포트폴리오</em>를 중점적으로 봅니다.
        </p>
      </div>
    </section>
  )
}

// const Announce = () => {
//   const [data, setData] = useState({
//     result: null,
//     loaded: false,
//     error: null,
//   });

//   useEffect(() => {
//     axios.get(
//       (process.env.NODE_ENV === 'development') ?
//         '/saramin.json' : '/saramin.php'
//     ).then((res) => {
//       setData({
//         result: res?.data?.[1]?.jobs?.job,
//         loaded: true,
//       });
//     }).catch(e=>{
//       console.log( e );
//       setData({
//         loaded: true,
//         error: `😥 ${e.message}: ${e.code}`
//       });
//     });
//   }, []);

//   if(data.error) {
//     return (<h2 style={{color:'red'}}>{data.error}</h2>)
//   }
//   return (
//     <section className="career-announce">
//       <div className="container">
//         <h2>
//           <span>즉시 지원 공고</span>
//         </h2>
//         {data.loaded && data?.length === 0 &&
//           <h2 style={{color: '#868e96'}}>
//             🤔&nbsp;
//             지원이 마감되었습니다
//           </h2>}
//         {data.loaded && <table>
//           <colgroup>
//             <col width="*" />
//           </colgroup>
//           <tbody>
//           {data.result?.map((d, i)=>(
//             <tr key={i}>
//               <td>
//                 <small>{d?.company?.detail?.name}</small>
//                 <h3>
//                   <a rel="noreferrer noopener" href={d?.url} target="_blank">
//                     {d?.position?.title}
//                   </a>
//                 </h3>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//         </table>}
//         <div className="powered">
//           Powered by <a rel="noreferrer noopener" href="http://www.saramin.co.kr/zf_user/company-info/view?csn=6088800869" target="_blank">Saramin</a>
//         </div>
//       </div>
//     </section>
//   )
// }

export default Career;