import  'react-app-polyfill/stable' ;
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';


// Browser에서 기본 언어 설정을 찾는다
var lang = (b=>{
  return (b.indexOf('ko')>=0) ? 'ko':(b.indexOf('ja')>= 0) ? 'ja':'en';
})((navigator.language || navigator.userLanguage).toLowerCase());

// 경로가 정확하게 언어를 사용해서 들어온 거라면 해당 경로를 언어로 설정한다
if ((/^\/ko\//).test( window.location.pathname )) {
  lang = 'ko';
}
if ((/^\/en\//).test( window.location.pathname )) {
  lang = 'en';
}

const root = ReactDOM.createRoot(document.getElementById('Body'));
root.render(
  <React.StrictMode>
    <App lang={lang} />
  </React.StrictMode>
);
