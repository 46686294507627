import Wrapper from 'common/Wrapper';
import 'styles/CaseStudy.scss';
import Curve from 'common/Curve';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faBuilding,  faDatabase,  faServer, faSkull} from '@fortawesome/pro-light-svg-icons';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { faWindow } from '@fortawesome/pro-duotone-svg-icons';

const CaseStudy = () => {
  return (
    <Wrapper active="CaseStudy" lang="en">
      <Intro />
      <Curve />
      <Slogan />
      <CaseA />
      <CaseDiagramA />
      <CaseEffectA />
      <CaseB />
      <CaseDiagramB />
      <CaseEffectB />
      <CaseC />
      <CaseDiagramC />
      <CaseEffectC />
      <CaseD />
      <CaseDiagramD1 />
      <CaseDiagramD2 />
    </Wrapper>
  )
};

const Intro = () => {
  return (
    <section className="case-intro">
      <div className="background">
        <canvas id="canvas" />
      </div>
      <div className="container">
        <h2>
          <img src="/images/common/bb_text_white.png"
            alt="Network Blackbox" />
          <br/>
          Case Study
        </h2>
      </div>
    </section>
  )
}

const Slogan = () => {
  return(
    <section className="case-slogan">
      <div className="container">
        <h2>
          Network Blackbox is getting progressively advanced<span className="break-line-auto"/>
          with its valuable experience with its clients
        </h2>
      </div>
    </section>
  )
}

const CaseA = () => {
  return (
  <section className="case-study">
    <div className="container">
      <h2>
      <FontAwesomeIcon icon={faBuilding} />&nbsp;
        Company of <em>A Case</em>
      </h2>
      <ul>
        <li>Interlocked with personnel database <em>to prevent leakage of data</em> and securing a list of former employees.</li>
        <li>Responding to various threats through <em>cyber attack detection</em> history</li>
      </ul>
    </div>
  </section>
  )
}

const CaseDiagramA = () => {
  return (
  <section className="case-diagram">
    <div className="container diagram-a">
      <div className="diagram">
        <figure className="line" />
        <figure className="device">
          <figure className="power" />
          <figcaption>SSL Proxy</figcaption>
        </figure>
        <figure className="line" />
        <figure className="device tap">
          <figure className="power" /><figure className="router" />
          <figcaption>TAP</figcaption>
        </figure>
        <figure className="line">
          <figure className="line-left" />
          <figure className="line-right" />
        </figure>
        <figure className="box">
          <figcaption>
            <span>Office</span>
            <span>Factory</span>
          </figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption>SSL Decryption</figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-right highlight">
          <figcaption>Mirroring</figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="empty" />
        <figure className="device network-blackbox">
          <figure className="power" />
          <figcaption>Network Blackbox</figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="empty" />
        <figure className="arrow arrow-left highlight">
          <figcaption>
            <span>Personnel DB</span>
          </figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="line" />
        <figure className="line" />
        <figure className="device siem">
          <figure className="power" />
          <figcaption>NAC</figcaption>
        </figure>
        <figure className="line" />
        <figure className="line" />
      </div>

      <div className="clearfix" />
    </div>
  </section>
  )
}

const CaseEffectA = () => {
  return (
  <section className="case-effect">
    <div className="container">
      <div className="wrapper">
        <div className="as-is">
          <h4>AS-IS</h4>
          <ul>
            <li>
              <FontAwesomeIcon icon={faServer} />&nbsp;
              <span>Block distribution server</span>
            </li>
          </ul>
        </div>
        <div className="to-be">
          <h4>TO-BE</h4>
          <ul>
            <li>
              <FontAwesomeIcon icon={faServer} />&nbsp;
              <span>Block distribution server</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faServer} />&nbsp;
              <span>Block C&amp;C server</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faUser} />&nbsp;
              <span>Check virus in users' PC</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faUser} />&nbsp;
              <span>Delete registry in users' PC</span>
            </li>
          </ul>
        </div>
      </div>
      <p>
      It was not possible to analyze previous traffic and get virus files because NAC devices only have logs. Therefore, only the distribution server was blocked. Since Network Blackbox is able to analyze the actual traffic it can detect the distribution server, C &amp; C server, the abnormal behavior of the users' PC, and even the droppers that launch the virus downloads.
      </p>
    </div>
  </section>
  )
}

const CaseB = () => {
  return (
  <section className="case-study">
    <div className="container">
      <h2>
        <FontAwesomeIcon icon={faServer} />&nbsp;
        <span>Company of <em>B Case</em></span>
      </h2>
      <ul>
        <li>100% full collection of traffic <em>from every separated network</em> environment</li>
        <li><em>Detects abnormal traffic</em> in conjunction with any kind of log solution</li>
        <li>
          Verifies true-positives and false-positives of existing solutions via looking at the actual traffic 
          <br />(detection log, pattern enhancement, blocking rule setting)
        </li>
      </ul>
    </div>
  </section>
  )
}

const CaseDiagramB = () => {
  return (
  <section className="case-diagram">
    <div className="container diagram-b">
      <div className="diagram">
        <figure className="line" />
        <figure className="device">
          <figure className="power" />
          <figcaption>SSL Proxy</figcaption>
        </figure>
        <figure className="line" />
        <figure className="device tap">
          <figure className="power" /><figure className="router" />
          <figcaption>TAP</figcaption>
        </figure>
        <figure className="line">
          <figure className="line-left" />
          <figure className="line-right" />
        </figure>
        <figure className="box">
          <figcaption>
            <span>IDC #1</span>
          </figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption>SSL Decryption</figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-right highlight">
          <figcaption>Mirroring</figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="empty" />
        <figure className="device network-blackbox">
          <figure className="power" />
          <figcaption>Network Blackbox</figcaption>
        </figure>
        <figure className="line" />
        <figure className="line" />
        <figure className="device siem">
          <figure className="power" />
          <figcaption>SIEM</figcaption>
        </figure>
        <figure className="empty" />
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="arrow arrow-left">
          <figcaption>SSL Decryption</figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-left highlight">
          <figcaption>Mirroring</figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="line" />
        <figure className="device">
          <figure className="power" />
          <figcaption>SSL Proxy</figcaption>
        </figure>
        <figure className="line" />
        <figure className="device tap">
          <figure className="power" /><figure className="router" />
          <figcaption>TAP</figcaption>
        </figure>
        <figure className="line">
          <figure className="line-left" />
          <figure className="line-right" />
        </figure>
        <figure className="box">
          <figcaption>
            <span>IDC #2</span>
          </figcaption>
        </figure>
      </div>
    </div>
  </section>
  )
}

const CaseEffectB = () => {
  return (
  <section className="case-effect">
    <div className="container">
      <div className="wrapper">
        <div className="as-is">
          <h4>AS-IS</h4>
          <ul>
            <li>
              <FontAwesomeIcon icon={faWindow} />&nbsp;
              <span>Web shell attack detection</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faServer} />&nbsp;
              <span>Log analysis</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faBan} />&nbsp;
              <span>Attacker IP blocking</span>
            </li>
          </ul>
        </div>
        <div className="to-be">
          <h4>TO-BE</h4>
          <ul>
            <li>
              <FontAwesomeIcon icon={faWindow} />&nbsp;
              <span>Web shell attack detection</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faServer} />&nbsp;
              <span>Log analysis</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faWindow} />&nbsp;
              <span>Web shell file acquisition, File analysis</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faSkull} />&nbsp;
              <span>Server malware detection and removal</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faBan} />&nbsp;
              <span>Server patching and attacker IP blocking</span>
            </li>
          </ul>
        </div>
      </div>
      <p>
      In the existing environment, IDS / IPS and SIEM equipment detected threats and blocked the attacker's IP, however the issue was that the actions of normal users were also blocked. Because the actual traffic could not be verified, it was extremely difficult to detect the attacks if the hacker changes IP or a problem occurs on the server. Network Blackbox distinguishes true-positive, false-positive and provides visibility of the network with the real traffic data.
      </p>
    </div>
  </section>
  )
}

const CaseC = () => {
  return (
  <section className="case-study">
    <div className="container">
      <h2>
        <FontAwesomeIcon icon={faDatabase} />&nbsp;
        Company of <em>C Case</em>
      </h2>
      <ul>
        <li>Detected information leakage by pipelining traffic to <em>big data analysis solution</em></li>
        <li>Executed by learning and responding to various threats through <em>keyword-based detection</em> and <em>machine learning</em></li>
      </ul>
    </div>
  </section>
  )
}

const CaseDiagramC = () => {
  return (
  <section className="case-diagram">
    <div className="container diagram-a">
      <div className="diagram">
        <figure className="line" />
        <figure className="device">
          <figure className="power" />
          <figcaption>SSL Proxy</figcaption>
        </figure>
        <figure className="line" />
        <figure className="device tap">
          <figure className="power" /><figure className="router" />
          <figcaption>TAP</figcaption>
        </figure>
        <figure className="line">
          <figure className="line-left" />
          <figure className="line-right" />
        </figure>
        <figure className="box">
          <figcaption>
            <span>Office</span>
            <span>Network</span>
          </figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption>SSL Decryption</figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-right highlight">
          <figcaption>Mirroring</figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="empty" />
        <figure className="device network-blackbox">
          <figure className="power" />
          <figcaption>Network Blackbox</figcaption>
        </figure>
        <figure className="empty" />
        <figure className="empty" />
        <figure className="empty" />
        <figure className="device bigdata">
          <figure className="power" />
          <figcaption>
            <span>Big Data Analytics</span>
          </figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="empty" />
        <figure className="arrow arrow-right highlight">
          <figcaption>Indexing</figcaption>
        </figure>
        <figure className="empty" />
        <figure className="empty" />
        <figure className="empty" />
        <figure className="arrow arrow-left highlight">
          <figcaption>
            <span>Integrating DB</span>
          </figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <label className="help-text">
          <span>Keep 3 months of traffic (880TB)</span>
        </label>
        <figure className="device datanode"><figcaption>DataNode #1</figcaption></figure>
        <figure className="device datanode"><figcaption>DataNode #2</figcaption></figure>
        <figure className="device datanode"><figcaption>DataNode #3</figcaption></figure>
        <figure className="device datanode"><figcaption>DataNode #4</figcaption></figure>
        <figure className="device datanode"><figcaption>DataNode #5</figcaption></figure>
        <figure className="device datanode"><figcaption>DataNode ...</figcaption></figure>
      </div>

      <div className="clearfix" />
    </div>
  </section>
  )
}

const CaseEffectC = () => {
  return (
  <section className="case-effect">
    <div className="container">
      <p>
      It was an environment that collected 100% of network traffic through Network Blackbox, processed collected data to analyze a specific user's behaviors or other abnormal behaviors by pipelining traffic to a big data analytic solution. We configured a distributed environment to properly balance loads and processed data at a high speed.
      </p>
    </div>
  </section>
  )
}

const CaseD = () => {
  return (
  <section className="case-study">
    <div className="container">
      <h2>
        <FontAwesomeIcon icon={faDatabase} />&nbsp;
        Company of <em>D Case</em>
      </h2>
      <ul>
        <li>
        Integrated multiple of vendor products (Web Security solutions, DLP, Analytics Solutions, Network Forensics) into a single <em>Network Blackbox</em>
        </li>
      </ul>
    </div>
  </section>
  )
}

const CaseDiagramD1 = () => {
  return (
  <section className="case-diagram">
    <h2 className="before">BEFORE</h2>
    <div className="container diagram-a">
      <div className="diagram">
        <figure className="line" />
        <figure className="line" />
        <figure className="line" />
        <figure className="device">
          <figure className="power" />
          <figcaption>SSL Proxy</figcaption>
        </figure>
        <figure className="line" />
        <figure className="device tap">
          <figure className="power" /><figure className="router" />
          <figcaption>TAP</figcaption>
        </figure>
        <figure className="line" />
        <figure className="line" />
        <figure className="line" />
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="empty" />
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption>SSL Decryption</figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-right highlight">
          <figcaption>Mirroring</figcaption>
        </figure>
      </div>

      <div className="diagram">
        <figure className="empty" />
        <figure className="empty" />
        <figure className="empty" />
        <figure className="empty" />
        <figure className="device">
          <figure className="power" />
          <figcaption style={{fontSize:'13px'}}>
            <span>Packet Collector (A)</span>
          </figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption></figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption></figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption></figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption></figcaption>
        </figure>
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="device siem">
          <figure className="power" />
          <figcaption style={{fontSize:'13px'}}>
            <span>Web security (B)</span>
          </figcaption>
        </figure>
        <figure className="empty" />
        <figure className="device siem">
          <figure className="power" />
          <figcaption style={{fontSize:'13px'}}>
            <span>DLP (C)</span>
          </figcaption>
        </figure>
        <figure className="empty" />
        <figure className="device siem">
          <figure className="power" />
          <figcaption style={{fontSize:'13px'}}>
            <span>Analytics solution (D)</span>
          </figcaption>
        </figure>
        <figure className="empty" />
        <figure className="device siem">
          <figure className="power" />
          <figcaption style={{fontSize:'13px'}}>
            <span>Network Forensic (E)</span>
          </figcaption>
        </figure>
      </div>
      <div className="clearfix" />
    </div>
  </section>
  )
}

const CaseDiagramD2 = () => {
  return (
  <section className="case-diagram">
    <hr/>
    <h2>AFTER</h2>
    <div className="container diagram-d2">
      <div className="diagram">
        <figure className="line" />
        <figure className="line" />
        <figure className="line" />
        <figure className="device">
          <figure className="power" />
          <figcaption>SSL Proxy</figcaption>
        </figure>
        <figure className="line" />
        <figure className="device tap">
          <figure className="power" /><figure className="router" />
          <figcaption>TAP</figcaption>
        </figure>
        <figure className="line" />
        <figure className="line" />
        <figure className="line" />
      </div>
      <div className="diagram">
        <figure className="empty" />
        <figure className="empty" />
        <figure className="empty" />
        <figure className="arrow arrow-right">
          <figcaption>SSL Decryption</figcaption>
        </figure>
        <figure className="empty" />
        <figure className="arrow arrow-right highlight">
          <figcaption>Mirroring</figcaption>
        </figure>
      </div>

      <div className="diagram">
        <br/><br/>
        <figure className="empty" />
        <figure className="box">
          <figcaption><img src="/images/common/bb_text_black.png" alt="txt_black"/></figcaption>
        </figure>
        <figure className="device network-blackbox">
          <figure className="power" />
          <figcaption>
            <span>Web security</span>
          </figcaption>
        </figure>
        <figure className="device network-blackbox">
          <figure className="power" />
          <figcaption>DLP</figcaption>
        </figure>
        <figure className="device network-blackbox">
          <figure className="power" />
          <figcaption>Flow Analysis</figcaption>
        </figure>
        <figure className="device network-blackbox">
          <figure className="power" />
          <figcaption>Network Forensic</figcaption>
        </figure>
      </div>

      <div className="clearfix" />
    </div>
  </section>
  )
}


export default CaseStudy;